import {
  ConversationParticipantType,
  Stack,
  VirtualizedList,
  defaultScrollbar,
  relativeFull,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useContactColumns } from './useContactColumns';

export const ConversationMembersTab: React.FC<{
  conversationId: string;
  members: ConversationParticipantType[];
}> = ({ members }) => {
  const columns = useContactColumns();

  return (
    <Stack direction="col" className={relativeFull}>
      <div
        className={classnames(
          'h-full w-full flex-1 overflow-y-auto',
          defaultScrollbar
        )}
      >
        <VirtualizedList
          columns={columns}
          headerHeight={0}
          rowHeight={72}
          infinityQuery={{
            data: members,
          }}
          classNames={{
            column: 'first:pl-5 last:pr-8',
          }}
        />
      </div>
    </Stack>
  );
};

export default ConversationMembersTab;
