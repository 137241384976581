import {
  CheckBox,
  SearchInput,
  Stack,
  VirtualizedList,
  commonText,
  useStateProvider,
} from '@trueconnect/tp-components';
import FilterCell from '@trueconnect/tp-components/src/components/ExtendedSearch/FilterCell';
import { useState } from 'react';
import { useApiInfiniteQuery } from 'src/api';
import { BrokerFilterType } from './typing';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export const ContactSearch = () => {
  const { extendedSearchParams } = useStateProvider().state.conversation;
  const selectedContacts = extendedSearchParams.selectedContacts || [];
  const setExtendedSearchParams =
    useStateProvider().actions.setExtendedSearchParams;
  const [searchParam, setSearchParam] = useState('');
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(false);

  const queryContacts = useApiInfiniteQuery('searchBrokers', [searchParam, 0], {
    select: (data) =>
      data.pages.flatMap((item) =>
        item.map(
          (subitem) =>
            ({
              ...subitem,
              name: subitem.displayName,
            } as BrokerFilterType)
        )
      ),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled,
  });

  const handleCheckBoxClick = (id: string) => {
    if (selectedContacts.includes(id)) {
      setExtendedSearchParams({
        ...extendedSearchParams,
        selectedContacts: selectedContacts.filter((item) => item != id),
      });
    } else {
      setExtendedSearchParams({
        ...extendedSearchParams,
        selectedContacts: [...selectedContacts, id],
      });
    }
  };

  return (
    <FilterCell
      title={t('conversation.extendedSearch.contactSearch.title')}
      setEnabled={setEnabled}
      active={true}
      dataTestId="extendedSearchContactFilter"
    >
      <div className="px-4 w-full">
        <SearchInput
          isMobileView={true}
          classNames={{
            wrapper: 'bg-white',
            input: 'bg-white text-sm',
            icon: 'mx-0 px-0 [&>div]:m-0 !w-7',
          }}
          setSearchParam={setSearchParam}
          placeholder={t(
            'conversation.extendedSearch.contactSearch.inputPlaceholder'
          )}
          dataTestid="extendedSearchContactInput"
        />
      </div>
      <Stack
        className={`w-full h-full pt-3 px-3`}
        isResizable={true}
        resizeDirection="ver"
        resizeProps={{
          width: -1,
          height: 180,
          resizeHandles: ['s'],
          minConstraints: [-1, 140],
          maxConstraints: [-1, 380],
        }}
      >
        <VirtualizedList
          infinityQuery={queryContacts}
          headerHeight={0}
          rowHeight={30}
          classNames={{
            row: 'border-none',
          }}
          columns={[
            {
              dataPropName: 'item',
              render: (item) => (
                <Stack
                  key={item.id}
                  direction="row"
                  className="cursor-pointer overflow-hidden"
                  alignItems="center"
                  onClick={() => {
                    handleCheckBoxClick(item.id);
                  }}
                  dataTestid={`extendedContactSearch_${item.email}`}
                >
                  <CheckBox
                    containerClassname="!w-7 !px-1"
                    checked={selectedContacts.includes(item.id)}
                    onChange={() => ({})}
                  />
                  <p
                    className={classnames(
                      commonText,
                      'text-sm !text-[#4B5563]'
                    )}
                  >
                    {item.name}
                  </p>
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    </FilterCell>
  );
};

export default ContactSearch;
