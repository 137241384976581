import { useMemo } from 'react';
import {
  IDocumentType,
  RowClickHandlerType,
  dateFormat,
  useGetDocumentsColumns,
} from '@trueconnect/tp-components';
import { useApiInfiniteQueryWithPageToken } from 'src/api';

export type PropsType = {
  searchParam?: string;
  conversationId: string;
  isDragActive: boolean;
  openFile?: RowClickHandlerType<IDocumentType> | undefined;
  enabled?: boolean;
};

export function useGetDocumentsTableProps(props: PropsType) {
  const {
    searchParam,
    isDragActive,
    conversationId,
    openFile,
    enabled = true,
  } = props;

  const columns = useGetDocumentsColumns();

  const queryDocuments = useApiInfiniteQueryWithPageToken(
    'search_Conversation_Documents',
    [conversationId, searchParam, undefined],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.documents
            ? item.documents.map(
                (subitem) =>
                  ({
                    ...subitem,
                    fileName: subitem.name,
                    modifiedAt: dateFormat(subitem.createdDateTime),
                    type: subitem.documentType === 1 ? 'folder' : 'file',
                  } as IDocumentType)
              )
            : []
        ),
      refetchOnWindowFocus: false,
      enabled: enabled,
    }
  );

  const FilesTableProps = useMemo(
    () => ({
      isDragActive,
      columns,
      queryDocuments,
      openFile,
    }),
    [columns, isDragActive, openFile, queryDocuments]
  );

  return FilesTableProps;
}

export default useGetDocumentsTableProps;
