import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import {
  Chat,
  DropZoneIcon,
  MessageType,
  Stack,
  UploadModal,
  dropZoneStyles,
  relativeFull,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useApiInfiniteQuery } from 'src/api';
import { useTranslation } from 'react-i18next';
import useGetDocumentsTableProps from './useGetDocumentsTableProps';
import useGetFileUrl from './GetFileUrl';
import { ConversationTabsProps } from '../ConversationTabs';
import { ReceiveMessageType } from '@trueconnect/tp-components/src/providers/HubListener/typing';

export const ConversationTab: React.FC<ConversationTabsProps> = (props) => {
  const { t } = useTranslation();
  const { setFilesToUpload, RemoveMessageNotification } =
    useStateProvider().actions;
  const { notifications } = useStateProvider().state.hubListener;
  const {
    userId,
    sendMessage,
    messages,
    setMessages,
    lastReadMessagesTimestamp,
  } = props;

  const { conversationId, readOnly } =
    useStateProvider().state.conversation.conversationData;
  const isMobileView = useIsSelectedView('sm');

  const queryMessages = useApiInfiniteQuery(
    'getMessages',
    [conversationId, 0],
    {
      select: (data) =>
        data.pages.flatMap((page) =>
          page.map((message) => {
            if (
              message.id &&
              notifications
                .map(
                  (item) => (item.notification as ReceiveMessageType).messageId
                )
                .includes(message.id)
            )
              RemoveMessageNotification(message.id);
            return message as MessageType;
          })
        ),
      errorMessage: t('conversation.loadError'),
      refetchOnWindowFocus: false,
    }
  );

  const { state, actions } = useStateProvider();
  const [message, setMessage] = useState('');
  const { isUploadPanelVisible } = state.uploads;
  const { setIsUploadPanelVisible } = actions;

  useEffect(() => {
    setMessages(queryMessages.data?.flat(1).reverse() || []);
  }, [queryMessages.data, setMessages]);

  const chatData = useMemo(
    () => ({
      conversationId: conversationId,
      user: userId ?? 'unknown',
      queryMessages,
      sendMessage,
      messages,
    }),
    [conversationId, userId, queryMessages, sendMessage, messages]
  );

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setIsUploadPanelVisible(true);
      setFilesToUpload(acceptedFiles);
    },
    noClick: true,
    noKeyboard: true,
    disabled: readOnly,
  });

  const FilesTableProps = useGetDocumentsTableProps({
    isDragActive,
    conversationId,
    enabled: isUploadPanelVisible,
  });

  return (
    <Stack direction="col" className={relativeFull}>
      <div
        {...getRootProps()}
        className={classnames(
          dropZoneStyles(isDragActive),
          !isMobileView && 'pl-8 pr-5'
        )}
      >
        <DropZoneIcon {...{ isDragActive }} />
        <Chat
          {...{
            ...chatData,
            visible: isDragActive,
            useGetFileUrl,
            lastReadMessagesTimestamp,
            message,
            setMessage,
          }}
        />
      </div>
      <UploadModal
        {...{
          visible: isUploadPanelVisible,
          sendMessage,
          FilesTableProps,
        }}
      />
    </Stack>
  );
};

export default ConversationTab;
