import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { msalConfig } from './authConfig';
import reportWebVitals from './reportWebVitals';
import { routes } from './routes';
import {
  initialize,
  LanguageControl,
  NotificationSystem,
  SmartSpinnerGlobal,
  StateProvider,
  ThemeProvider,
} from '@trueconnect/tp-components';
import AppProviders from './pages/App/AppProviders';
import { FeatureFlags } from './utils';

import './i18n';
import './index.css';

initialize({ featureFlags: FeatureFlags });

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const queryClient = new QueryClient();

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <StateProvider>
          <SmartSpinnerGlobal>
            <NotificationSystem>
              <MsalProvider instance={msalInstance}>
                <QueryClientProvider client={queryClient}>
                  <AppProviders>
                    <LanguageControl />
                    <ThemeProvider>{routes()}</ThemeProvider>
                  </AppProviders>
                </QueryClientProvider>
              </MsalProvider>
            </NotificationSystem>
          </SmartSpinnerGlobal>
        </StateProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
