import {
  DocumentIcon,
  IconButton,
  Stack,
  currencyFormat,
  formatDate,
  invoiceType,
  useGetColumns,
  useIsSelectedRange,
} from '@trueconnect/tp-components';
import {
  Column,
  RewardTypeColumn,
} from '@trueconnect/tp-components/src/components/policy/InvoiceTable/columns';
import { t } from 'i18next';
import { Actions } from './columns';

export const FileIcon = (item: invoiceType) => {
  return (
    <IconButton
      hoverable={false}
      className="relative"
      dataTestId={`invoiceDownload_${item.id}`}
    >
      {item.isNewFile && (
        <div className="absolute top-0 left-4 w-3 h-3 bg-blue-500 rounded-full" />
      )}
      <DocumentIcon />
    </IconButton>
  );
};

const FromColumn = (item: invoiceType) => {
  return (
    <Stack direction="row" alignItems="center">
      {FileIcon(item)}
      {Column(formatDate(item.dateFrom))}
    </Stack>
  );
};

export const PriceColumn = (item: invoiceType) => {
  return (
    <Stack direction="row" className="w-full">
      <p className="ml-auto">{currencyFormat(item.amount)}</p>
    </Stack>
  );
};

export const DatePeriod = (item: invoiceType) => {
  return (
    <Stack className="w-full" direction="row" alignItems="center">
      {Column(formatDate(item.dateFrom))}
      <div className="px-2">-</div>
      {Column(formatDate(item.dateTo))}
    </Stack>
  );
};

export const PricesColumn = (item: invoiceType) => {
  return (
    <Stack direction="col" className="w-full gap-1">
      <Stack
        className="w-full overflow-hidden [&>div]:w-auto leading-[20px]"
        direction="row"
        alignItems="center"
      >
        <p className="w-full text-nowrap truncate">
          {t('policyDetails.invoiceTable.price')}
        </p>
        {PriceColumn(item)}
      </Stack>
    </Stack>
  );
};

export const MobileListItem = (item: invoiceType) => {
  return (
    <Stack className="w-full py-2" direction="col">
      <Stack className="w-full pb-2" direction="row">
        <Stack className="w-full" direction="row" alignItems="center">
          <div className="relative pr-3">
            {!item.firstTimeDownloadedUtc && !!item.documentId && (
              <div className="absolute -top-1 left-3 w-3 h-3 bg-blue-500 rounded-full" />
            )}
            <IconButton hoverable={false} className="!m-0 !p-0 !w-5">
              {!item.documentId ? (
                <DocumentIcon color="#E5E7EB" />
              ) : (
                <DocumentIcon />
              )}
            </IconButton>
          </div>
        </Stack>
        {Actions(item)}
      </Stack>

      <Stack direction="col" className="w-full p-1 gap-1">
        {item.policyRewardTypeName && RewardTypeColumn(item)}
        {DatePeriod(item)}
        {PricesColumn(item)}
      </Stack>
    </Stack>
  );
};

export const useGetInvoiceColumns = () => {
  const columns = useGetColumns();
  const isMobileView = useIsSelectedRange(['sm', 'md']);
  const isTabletView = useIsSelectedRange(['lg', 'xl']);

  if (isMobileView) {
    return [
      {
        dataPropName: 'mobileItem',
        render: MobileListItem,
      },
    ];
  }

  if (isTabletView) {
    return [
      {
        dataPropName: 'data',
        render: (item: invoiceType) => {
          return (
            <Stack direction="col" className="h-full">
              <Stack direction="row" alignItems="center" className="h-full">
                {FileIcon(item)}
                {DatePeriod(item)}
              </Stack>
              <div className="pl-[40px]">{RewardTypeColumn(item)}</div>
            </Stack>
          );
        },
      },
      {
        title: (
          <p className="text-end ml-auto">
            {t('policyDetails.invoiceTable.price')}
          </p>
        ),
        dataPropName: 'price',
        render: PriceColumn,
        className: '!justify-end',
        widthRatio: 1,
      },
      {
        dataPropName: 'actions',
        title: t('policyDetails.invoiceTable.invoice'),
        render: Actions,
        className: '!text-end pr-3',
      },
    ];
  }

  return [
    {
      title: t('policyDetails.invoiceTable.from'),
      dataPropName: 'from',
      render: FromColumn,
      className: 'pl-[45px]',
    },
    ...columns.filter((item) => item.dataPropName !== 'price'),
    {
      title: (
        <p className="text-end ml-auto">
          {t('policyDetails.invoiceTable.price')}
        </p>
      ),
      dataPropName: 'price',
      render: PriceColumn,
      className: '!justify-end',
      widthRatio: 2,
    },
    {
      dataPropName: 'actions',
      title: t('policyDetails.invoiceTable.invoice'),
      render: Actions,
      className: '!text-end pr-3',
      widthRatio: 2.8,
    },
  ];
};
