import {
  ContextHelpIcon,
  CreateConversationButton,
  MaintenanceInfo,
  NavbarLayout,
  NotificationIcon,
  Stack,
  UserAvatarDropdown,
  true_partners,
  useIsSelectedView,
  useStateProvider,
  useUserProfile,
} from '@trueconnect/tp-components';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import SelectedCompany from './SelectedCompany';
import CreateConversationPanelCustomer from './CreateConversationPanelCustomer';
import docs from './../pages/App/Help/help.json';
import { HelpType } from '@trueconnect/tp-components/src/pages/Help';
import { blobStorageAccountName, maintenanseFolder } from 'src/authConfig';

const imgClass = classNames('cursor-pointer select-none mr-5');

export const UserNavbar = () => {
  const navigator = useNavigate();
  const { showCreateConversation } = useStateProvider().state.conversation;
  const { customerName, permissions } = useUserProfile();

  const goToDashboard = (e: React.FormEvent) => {
    e.preventDefault();
    navigator('/');
  };

  const isMobile = useIsSelectedView('sm');

  return (
    <>
      <MaintenanceInfo
        mode="banner"
        blobStorageAccountName={blobStorageAccountName}
        maintenanseFolder={maintenanseFolder}
      />
      <NavbarLayout
        logos={
          isMobile
            ? []
            : [
                <img
                  key="true_partners"
                  src={true_partners}
                  alt="True Partners"
                  width="100"
                  height={isMobile ? '24' : '30'}
                  className={imgClass}
                  onClick={goToDashboard}
                />,
              ]
        }
      >
        {customerName && <SelectedCompany name={customerName} />}
        <Stack direction="row" alignItems="center">
          {!isMobile && permissions?.allowConversations && (
            <CreateConversationButton />
          )}
          <ContextHelpIcon {...{ docs: docs as HelpType[] }} />
          <NotificationIcon />
          <UserAvatarDropdown hideSettings={true} />
          {showCreateConversation && <CreateConversationPanelCustomer />}
        </Stack>
      </NavbarLayout>
    </>
  );
};

export default UserNavbar;
