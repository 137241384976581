import {
  DocumentBreadCrumb,
  IDocumentType,
  RowClickHandlerType,
  VirtualizedList,
  breadCrumbType,
  dateFormat,
  formatHierarchy,
  initialBreadcrumb,
  useIsSelectedView,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce, useLocation } from 'react-use';
import classnames from 'classnames';
import {
  CustomerAppLogic,
  useApiInfiniteQueryWithPageToken,
  useApiQuery,
} from 'src/api';
import { useGetColumns } from './useGetColumns';
import { useQuery } from '@tanstack/react-query';

export const CustomerDocumentPage = () => {
  const { addNotification } = useNotificationSystem();
  const { t } = useTranslation();
  const { pathname: location, hash: params } = useLocation();
  const navigate = useNavigate();

  const [parentId, setParentId] = useState<string>();
  const [breadcrumb, setBreadcrumbs] = useState<breadCrumbType[]>([]);
  const [activeDocId, setActiveDocId] = useState<string>('');

  useEffect(() => {
    const url = window.location.href.replace('#?', '?');
    const urlParams = new URLSearchParams(url.split('?')[1]);
    const downloadParam = urlParams.get('download');

    downloadParam && setActiveDocId(downloadParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const { data: fileUrl, refetch: fetchDownloadUrl } = useApiQuery(
    'gets_Direct_Shared_Document_Download_Url',
    [activeDocId],
    {
      enabled: false,
      onError: () => {
        addNotification({
          title: 'Document not found',
          color: 'failure',
        });
      },
    }
  );

  useEffect(() => {
    const hash = params?.split('?')[0];
    if (hash) {
      const value = hash.substring(1);
      setParentId(value);
      const index = breadcrumb.findIndex((obj) => obj.path === value);
      setBreadcrumbs(breadcrumb.filter((_, idx) => idx <= index));
    } else {
      if (breadcrumb.length) {
        setBreadcrumbs([]);
      }
      setParentId('');
    }
  }, [breadcrumb, params]);

  const {
    data: result,
    refetch,
    isSuccess,
  } = useQuery({
    queryKey: ['get_Document_Customer_Hierarchy_slice'],
    queryFn: async () => {
      const hash = params?.split('?')[0];
      if (!hash) return {};
      return await CustomerAppLogic.get_Document_Customer_Hierarchy_slice(
        hash.substring(1)
      );
    },
    enabled: false,
  });

  useEffect(() => {
    if (isSuccess) {
      const formatted = formatHierarchy(result);
      setBreadcrumbs(
        formatted
          .map((item) => {
            return { name: item.name, path: item.id } as breadCrumbType;
          })
          .slice(1)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffectOnce(() => {
    refetch();
  });

  useEffect(() => {
    if (activeDocId) {
      fetchDownloadUrl();
    }
  }, [activeDocId, fetchDownloadUrl]);

  useEffect(() => {
    if (fileUrl?.signedUrl) {
      window.open(fileUrl.signedUrl);
      setActiveDocId('');
    }
  }, [fileUrl]);

  const downloadFile = useCallback((documentId: string) => {
    setActiveDocId(documentId);
  }, []);

  const columns = useGetColumns({ downloadFile });

  const queryDocuments = useApiInfiniteQueryWithPageToken(
    'search_My_Documents',
    [undefined, parentId, undefined],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.documents
            ? item.documents.map(
                (subitem) =>
                  ({
                    ...subitem,
                    fileName: subitem.name,
                    modifiedAt: dateFormat(subitem.createdDateTime),
                    type: subitem.documentType === 1 ? 'folder' : 'file',
                  } as IDocumentType)
              )
            : []
        ),
      refetchOnWindowFocus: false,
    }
  );

  const openFile: RowClickHandlerType<IDocumentType> = useCallback(
    (item: IDocumentType) => {
      if (item.type === 'folder') {
        navigate(`${location}#${item.id}`);
        setBreadcrumbs((prev) => [
          ...prev,
          { name: item.fileName, path: item.id },
        ]);
      } else {
        downloadFile(item.id);
      }
    },
    [downloadFile, location, navigate]
  );

  const isMobileView = useIsSelectedView('sm');

  return (
    <div
      className={classnames(
        'w-full h-full relative flex flex-col bg-customColor_17',
        !isMobileView && 'px-8'
      )}
    >
      <DocumentBreadCrumb
        pathname={`${location}`}
        items={[initialBreadcrumb, ...breadcrumb]}
      />
      <div className="h-full flex-1">
        <VirtualizedList
          columns={columns}
          infinityQuery={queryDocuments}
          headerHeight={40}
          rowHeight={70}
          onRowClick={openFile}
          nothingFound={t('fileToolbar.empty.folder')}
          classNames={{
            header: '!bg-customColor_17 font-normal !px-0 first:indent-0',
            row: 'hover:bg-customColor_2 cursor-pointer',
          }}
        />
      </div>
    </div>
  );
};

export default CustomerDocumentPage;
