import { useRef, useState, useEffect } from 'react';
import { BrokerContactListItem } from './BrokerContactListItem';
import classnames from 'classnames';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  IconButton,
  Stack,
  defaultScrollbar,
  useConfig,
  useIsSelectedRange,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useWindowSize } from 'react-use';
import { t } from 'i18next';
import MobileBrokerContacts from './MobileBrokerContacts';

export const BrokerContacts = () => {
  const [showAll, setShowAll] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  const { configState } = useConfig();
  const isMobileView = useIsSelectedRange(['sm', 'md']);
  const isSmallView = useIsSelectedRange(['sm']);

  const { availableBrokers } = useStateProvider().state.workManagement;

  const toggleShowAll = () => {
    const container = ref.current;
    if (container) {
      setShowAll(!showAll);
      container.scrollTop = 0;
    }
  };

  const contactRowHeight = 360;
  useEffect(() => {
    const container = ref.current;
    if (container) {
      setShowButton(container.scrollHeight > contactRowHeight);
    }
  }, [width, configState.FullSidebar, contactRowHeight]);

  if (!availableBrokers.length) {
    return <></>;
  }

  if (isMobileView) {
    return (
      <div className={classnames('w-full', isSmallView && 'px-4')}>
        <MobileBrokerContacts {...{ contacts: availableBrokers }} />
      </div>
    );
  }

  return (
    <div className={classnames('w-full')}>
      <p className="text-lg font-semibold pt-6 pb-4">
        {t('dashboard.contactBrokers.title')}
      </p>
      <div
        className="w-full h-full flex items-center"
        style={{
          minHeight: contactRowHeight,
        }}
      >
        <div
          className={classnames(
            defaultScrollbar,
            'w-full h-full pt-4',
            showAll ? 'overflow-auto' : `overflow-hidden`
          )}
          style={{
            maxHeight: showAll ? 600 : contactRowHeight,
          }}
          ref={ref}
        >
          <Stack
            direction={'row'}
            className={classnames('w-full flex-wrap gap-5 pb-4')}
          >
            {availableBrokers.map((item) => (
              <BrokerContactListItem
                key={item.displayName}
                {...{ item, isManager: item.isManager }}
              />
            ))}
          </Stack>
        </div>
      </div>
      {showButton && (
        <Stack
          direction="row"
          alignItems="center"
          className={classnames(
            'text-[#0170df] text-base font-medium cursor-pointer pb-8 pt-2'
          )}
          onClick={toggleShowAll}
        >
          <IconButton className="!m-0 !p-0 !w-5 !mr-1" hoverable={false}>
            {showAll ? (
              <ChevronUpIcon color="#0170df" />
            ) : (
              <ChevronDownIcon color="#0170df" />
            )}
          </IconButton>
          <p>
            {t(`dashboard.contactBrokers.${showAll ? 'hideAll' : 'showAll'}`)}
          </p>
        </Stack>
      )}
    </div>
  );
};

export default BrokerContacts;
