import Modal from '@trueconnect/tp-components/src/controls/Modal';
import { t } from 'i18next';
import { MobileBrokerContactListItem } from './MobileBrokerContactListItem';
import {
  ContactBrokerType,
  Stack,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import classnames from 'classnames';

export const BrokerContactsModal = ({
  visible,
  onClose,
  contacts,
}: {
  visible?: boolean;
  onClose?: () => void;
  contacts: ContactBrokerType[];
}) => {
  const isMobileView = useIsSelectedView('sm');
  return (
    <Modal
      show={visible}
      size="xl"
      onClose={onClose}
      showFooter={false}
      title={t('dashboard.contactBrokers.title')}
      className={classnames(
        '[&>div>div]:h-full [&>div>div]:max-h-[100vh]',
        isMobileView && '[&>div]:max-w-full',
        '[&>div>div>div:nth-child(2)]:px-2 [&>div>div>div:nth-child(2)]:py-3'
      )}
    >
      <Stack className="w-full h-full gap-3 pt-2">
        {contacts.map((item) => (
          <div key={item.userId} className="w-full" onClick={onClose}>
            <MobileBrokerContactListItem {...{ item }} />
          </div>
        ))}
      </Stack>
    </Modal>
  );
};

export default BrokerContactsModal;
