import {
  Stack,
  IconButton,
  ChevronDoubleLeftIcon,
  SearchInput,
  ChevronDoubleRightIcon,
  MagnifyingGlassIcon,
} from '@trueconnect/tp-components';
import { Dispatch, SetStateAction } from 'react';
import classnames from 'classnames';
import { t } from 'i18next';

export const ConversationTopicsSidebarHeader = ({
  setSearchParam,
  isFull,
  setIsFull,
  variant = 'primary',
}: {
  setSearchParam: Dispatch<SetStateAction<string>>;
  isFull: boolean;
  setIsFull: (value: boolean) => void;
  variant?: 'primary' | 'secondary';
}) => {
  return (
    <Stack direction="col" className={classnames('w-full')} alignItems="center">
      <Stack
        direction="row"
        className="w-full h-14 border-b"
        alignItems="center"
        justifyContent="between"
      >
        <Stack direction="row" alignItems="center">
          <IconButton
            dataTestId="toggleTopicsSidebar"
            hoverable={false}
            onClick={() => {
              setIsFull(!isFull);
            }}
          >
            {isFull ? <ChevronDoubleLeftIcon /> : <ChevronDoubleRightIcon />}
          </IconButton>
          {isFull && variant == 'primary' && (
            <Stack
              direction="row"
              alignItems="center"
              className="select-none leading-tight text-lg font-semibold"
            >
              {t('sidebar.topics')}
            </Stack>
          )}
        </Stack>
      </Stack>

      {isFull && (
        <Stack
          direction="row"
          alignItems="center"
          className="w-full h-14 border-b pr-3 bg-customColor_7"
        >
          <SearchInput
            classNames={{
              wrapper: 'bg-customColor_7 [&>.closeIcon]:mr-0 !border-none',
              input: 'bg-customColor_7',
            }}
            dataTestid="topicsSidebarSearch"
            setSearchParam={setSearchParam}
            isMobileView={true}
            iconHoverable={false}
            searchIcon={<MagnifyingGlassIcon color="#1C64F2" />}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ConversationTopicsSidebarHeader;
