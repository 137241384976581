import {
  ArrowDownTrayIcon,
  IconButton,
  Spinner,
  Stack,
  hoverClassname,
  invoiceType,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { t } from 'i18next';
import { useDownloadInvoiceDocument } from './useDownloadInvoiceDocument';
import { useState } from 'react';

export const Actions = (item: invoiceType) => {
  const [documentInvoiceId, setDocumentInvoiceId] = useState<string>();

  const { isLoading } = useDownloadInvoiceDocument(documentInvoiceId, () => {
    setDocumentInvoiceId('');
  });

  return (
    <Stack
      direction="row"
      className="w-full h-full pr-2"
      alignItems="center"
      justifyContent="end"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Stack
          direction="row"
          dataTestid={`download_${item.id}`}
          alignItems="center"
          className={classnames(
            'text-[#1C64F2] p-1 rounded-lg',
            hoverClassname
          )}
          onClick={() => {
            item.id && setDocumentInvoiceId(item.id);
          }}
        >
          <p className="text-nowrap">{t('policyDetails.download')}</p>
          <IconButton hoverable={false} className="!m-0 !p-0 w-[20px] !ml-2">
            <ArrowDownTrayIcon color="#1C64F2" />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};
