import {
  Stack,
  useUserProfile,
  useStateProvider,
  MessageType,
  CommonStyles,
  ConversationParticipantType,
  useOneParticipant,
  useIsSelectedView,
  relativeFull,
  useIsSelectedRange,
  deleteUnsentMessage,
  ConversationTopicHeader,
} from '@trueconnect/tp-components';
import { useMemo, useState } from 'react';
import { useApiMutation, AttachmentDto } from 'src/api';
import ConversationTabs from './ConversationTabs';
import classnames from 'classnames';
import UploadManager from 'src/providers/UploadManager';
import ConversationHeader from './Actions/ConversationHeader';
import { UserActions } from './Actions/UserActions';

export const ConversationDetails = () => {
  const { userId } = useUserProfile();
  const { conversationData } = useStateProvider().state.conversation;
  const { conversationId, participants, lastReadMessagesTimestamp, title } =
    conversationData;

  const [messages, setMessages] = useState<MessageType[]>([]);

  const isConversationMember = useMemo(() => {
    return conversationData?.participants?.find(
      (item) => item.userId === userId
    );
  }, [conversationData, userId]);

  const { mutate } = useApiMutation('send_Message');

  const sendMessage = (message: string, attachments?: AttachmentDto[]) => {
    const preLoadMessage = {
      messageText: message,
      senderUserId: userId,
      loading: true,
      attachments,
    } as MessageType;
    setMessages([...messages, preLoadMessage]);
    mutate([conversationId, { message, attachments }], {
      onSuccess: () => {
        deleteUnsentMessage(conversationId);
      },
    });
  };

  const participant = useOneParticipant(
    participants as ConversationParticipantType[]
  );

  const isMobileView = useIsSelectedView('sm');
  const isSelectedView = useIsSelectedRange(['sm', 'md', 'lg']);

  return (
    <Stack
      alignItems="center"
      direction="col"
      className={classnames(
        CommonStyles.full,
        isMobileView && '!fixed top-0 left-0 right-0 bottom-0 transition-all'
      )}
    >
      <ConversationTopicHeader
        {...{
          data: {
            id: conversationData.topicId,
            displayName: conversationData.topicName,
          },
          classNames: classnames('pb-3', isMobileView ? 'px-2' : 'pl-8 pr-5'),
        }}
      />
      <Stack
        direction="col"
        className={classnames('w-full', !isMobileView && 'pl-8 pr-5')}
      >
        <Stack
          direction="row"
          className="w-full pt-2"
          alignItems="center"
          justifyContent="between"
        >
          <ConversationHeader
            {...{
              title: title || '',
              participant,
            }}
          />
        </Stack>
        {!isSelectedView && <UserActions participant={participant} />}
      </Stack>
      <div className={relativeFull}>
        {conversationId && (
          <UploadManager id={conversationId}>
            <ConversationTabs
              {...{
                sendMessage,
                userId,
                messages,
                setMessages,
                isConversationMember,
                lastReadMessagesTimestamp,
              }}
            />
          </UploadManager>
        )}
      </div>
    </Stack>
  );
};

export default ConversationDetails;
