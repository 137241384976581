import {
  ContactPermissionDto,
  ConversationsIcon,
  DocumentIcon,
  HelpIcon,
  HomeIcon,
  NavbarItemProps,
  ClipboardDocumentListIcon,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';

export const useSidebarConfig = ({
  permissions,
}: {
  permissions?: ContactPermissionDto | undefined;
}) => {
  const { t } = useTranslation();

  const mainSidebarElements: NavbarItemProps[] = [
    {
      title: t('sidebar.dashboard'),
      path: '/',
      icon: <HomeIcon />,
    },
  ];

  if (permissions?.allowConversations) {
    mainSidebarElements.push({
      title: t('sidebar.conversations'),
      path: '/conversations',
      icon: <ConversationsIcon />,
    });
  }

  if (permissions?.allowSharedDocuments) {
    mainSidebarElements.push({
      title: t('sidebar.documents'),
      path: '/documents',
      icon: <DocumentIcon />,
    });
  }

  if (permissions?.allowPolicyDirectory) {
    mainSidebarElements.push({
      title: t('sidebar.policies'),
      path: '/policies',
      icon: <ClipboardDocumentListIcon />,
    });
  }

  const settingsSidebarElement: NavbarItemProps[] = [
    {
      title: t('sidebar.help'),
      path: '/help',
      icon: <HelpIcon />,
    },
  ];

  return [mainSidebarElements, settingsSidebarElement];
};
