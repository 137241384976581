import {
  ArrowDownTrayIcon,
  DocumentCellStyle,
  Dropdown,
  DropDownItemType,
  EllipsisHorizontalIcon,
  IDocumentType,
  useIsSelectedRange,
} from '@trueconnect/tp-components';
import { IconButton } from '@trueconnect/tp-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const More: React.FC<{
  item: IDocumentType;
  downloadFile: (id: string) => void;
}> = ({ item, downloadFile }) => {
  const { t } = useTranslation();
  const isMobileView = useIsSelectedRange(['sm', 'md']);

  const items: DropDownItemType[] = [
    {
      icon: <ArrowDownTrayIcon data-testid={`download_${item.fileName}`} />,
      text: t('file.action.download') as string,
      onClick: () => downloadFile(item.id),
    },
  ];

  return (
    <div
      className={DocumentCellStyle}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dropdown
        label={
          <IconButton>
            <EllipsisHorizontalIcon
              data-testid={`documentAction_${item.fileName}`}
            />
          </IconButton>
        }
        mobileView={isMobileView}
        items={items}
      />
    </div>
  );
};
