import { loginRequest } from '../../authConfig';
import { getContactImage, useGetUserProfile } from 'src/utils';
import {
  ConfigProvider,
  RequestInterceptor,
  UserProfileProvider,
  Error,
  ErrorBoundary,
  HubListenerProvider,
  useStateProvider,
  sortBranches,
  SmartSpinnerLocal,
  ContactBrokerType,
} from '@trueconnect/tp-components';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType, AuthError } from '@azure/msal-browser';
import { useApiQuery } from 'src/api';
import { Suspense, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useEffectOnce } from 'react-use';

type AppProvidersType = {
  children: React.ReactNode;
};

const MsalAuthenticationLoading = () => <></>;

const MsalErrorComponent = ({ error }: { error: AuthError | null }) => (
  <Error height="full" title={error?.name} subTitle={error?.errorMessage} />
);

const serverUrl = `${process.env.REACT_APP_API_URL}/api/v1/customerapplogic`;

export const AppProviders: React.FC<AppProvidersType> = ({ children }) => {
  const { instance } = useMsal();

  return (
    <Suspense>
      <ErrorBoundary
        refreshToken={async () => {
          await instance.loginRedirect({ ...loginRequest });
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ ...loginRequest }}
            loadingComponent={MsalAuthenticationLoading}
            errorComponent={MsalErrorComponent}
          >
            <RequestInterceptor loginRequest={loginRequest}>
              <ConfigProvider>
                <SecurityProvider>{children}</SecurityProvider>
              </ConfigProvider>
            </RequestInterceptor>
          </MsalAuthenticationTemplate>
        </DndProvider>
      </ErrorBoundary>
    </Suspense>
  );
};

export const SecurityProvider: React.FC<AppProvidersType> = ({ children }) => {
  const { userProfile } = useGetUserProfile();

  //refresh urls of cached images
  useEffectOnce(() => {
    sessionStorage.setItem('cachedImages', '{}');
  });

  return (
    <UserProfileProvider userProfile={userProfile}>
      <HubListenerProvider
        serverUrl={serverUrl}
        loginRequest={loginRequest}
        permissions={userProfile.permissions}
      >
        <BranchesProvider> {children}</BranchesProvider>
      </HubListenerProvider>
    </UserProfileProvider>
  );
};

export const BranchesProvider: React.FC<AppProvidersType> = ({ children }) => {
  const { actions } = useStateProvider();
  const { userProfile } = useGetUserProfile();
  const { setAvailableBranches, setAvailableBrokers } = actions;
  const [isBrokersLoading, setIsBrokersLoading] = useState(true);

  const { isLoading } = useApiQuery('getAvailableBranches', [], {
    onSuccess: (data) => {
      setAvailableBranches(sortBranches(data));
    },
    enabled: !!userProfile.userId,
  });

  const { data } = useApiQuery('getAvailablePolicyBrokers', [], {
    onError: () => {
      setIsBrokersLoading(false);
      setAvailableBrokers([]);
    },
    enabled: !!userProfile.userId,
  });

  useEffect(() => {
    if (data) {
      const fetchImages = async () => {
        const contactsWithImages = await Promise.all(
          data
            .sort((a, b) =>
              a.isAccountManager && !b.isAccountManager
                ? -1
                : !a.isAccountManager && b.isAccountManager
                ? 1
                : 0
            )
            .map(
              async (item) =>
                ({
                  ...item,
                  image: await getContactImage(item.imageId),
                  phoneNumber: item.directPhoneNumber,
                  isManager: item.isAccountManager,
                  branchIds: item.responsibleForBranchIds,
                } as ContactBrokerType)
            )
        );
        setAvailableBrokers(contactsWithImages);
        setIsBrokersLoading(false);
      };
      fetchImages();
    }
  }, [data, setAvailableBrokers]);

  return (
    <SmartSpinnerLocal
      name="getAvailableBranches"
      condition={isLoading || isBrokersLoading}
      renderAsGlobal={true}
    >
      {children}
    </SmartSpinnerLocal>
  );
};

export default AppProviders;
