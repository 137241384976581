import { Route, Routes } from 'react-router-dom';
import App from './pages/App';
import { Error as ErrorPage } from '@trueconnect/tp-components';

export const routes = () => {
  return (
    <Routes>
      <Route path="/*" element={<App />} />
      <Route path="/error_page" element={<ErrorPage />} />
    </Routes>
  );
};
