import {
  IconButton,
  PolicyType,
  EllipsisHorizontalIcon,
  Dropdown,
  PlusIcon,
  useStateProvider,
  ContactBrokerType,
  useIsSelectedRange,
  Stack,
  ArrowRightIcon,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';

export const Badge = ({ data }: { data: PolicyType }) => {
  const { state, actions } = useStateProvider();
  const { availableBrokers } = state.workManagement;
  const { setBrokerData, setShowCreateConversation } = actions;
  const isMobileView = useIsSelectedRange(['sm', 'md']);

  const policyManager = availableBrokers.find(
    (item) =>
      item.email?.toLowerCase() === data.policyManager?.email?.toLowerCase()
  );
  const accountManager = availableBrokers.find(
    (item) =>
      item.email?.toLowerCase() === data.accountManager?.email?.toLowerCase()
  );

  const parseData = (item?: ContactBrokerType) => {
    return {
      id: item?.userId,
      displayName: item?.displayName,
      email: item?.email,
    };
  };

  return (
    <Stack
      className={classnames(
        'h-full',
        isMobileView ? 'pr-[10px]' : 'w-full pr-[26px]'
      )}
      direction="row"
      alignItems="center"
      justifyContent="end"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Dropdown
          trigger="hover"
          className="max-w-[240px] [&>ul>li]:h-[56px] [&>ul>li>button]:text-black [&>ul>li>button]:h-[56px] [&>ul>li>button]:text-start"
          label={
            <IconButton className="my-auto">
              <EllipsisHorizontalIcon />
            </IconButton>
          }
          mobileView={isMobileView}
          items={[
            {
              icon: <PlusIcon color="black" />,
              text: t('policies.createConversationWithPolicyManager'),
              classNames: classnames(
                !policyManager?.userId &&
                  '!text-customColor_4 !cursor-not-allowed'
              ),
              onClick: () => {
                if (policyManager?.userId) {
                  setShowCreateConversation(true);
                  setBrokerData(parseData(policyManager));
                }
              },
            },
            {
              icon: <PlusIcon color="black" />,
              text: t('policies.createConversationWithAccountManager'),
              classNames: classnames(
                !accountManager?.userId &&
                  '!text-customColor_4 cursor-not-allowed'
              ),
              onClick: () => {
                if (accountManager?.userId) {
                  setShowCreateConversation(true);
                  setBrokerData(parseData(accountManager));
                }
              },
            },
          ]}
        />
      </div>
      <IconButton className="my-auto arrowIcon hidden pr-[6px]">
        <ArrowRightIcon color="#0170DF" />
      </IconButton>
    </Stack>
  );
};
