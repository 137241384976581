import {
  DocumentToolbar,
  FilesTable,
  IDocumentType,
  LocalErrorBoundary,
  ProgressDialog,
  RowClickHandlerType,
  SmartSpinnerLocal,
  useIsSelectedView,
  useStateProvider,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';

import useGetDocumentsTableProps from './useGetDocumentsTableProps';
import { useCallback, useEffect, useState } from 'react';
import GetFileUrl from './GetFileUrl';
import { AttachmentDto } from 'src/api';
import { HttpStatusCode } from '@trueconnect/tp-components/src/components/ErrorBoundary/typing';

type ConversationDocumentsTabType = {
  sendMessage: (message: string, attachments?: AttachmentDto[]) => void;
};

export const ConversationDocumentsTab: React.FC<
  ConversationDocumentsTabType
> = ({ sendMessage }) => {
  const [activeDocId, setActiveDocId] = useState<string>('');
  const { state, actions } = useStateProvider();
  const { isUploadPanelVisible, selectedFiles, filesToUpload } = state.uploads;
  const { setSelectedFiles, setIsUploadPanelVisible } = actions;
  const { conversationId, disableUpload } =
    useStateProvider().state.conversation.conversationData;

  const isMobileView = useIsSelectedView('sm');

  const { setFilesToUpload } = useStateProvider().actions;

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFilesToUpload(acceptedFiles);
      setIsUploadPanelVisible(true);
    },
    noClick: true,
    noKeyboard: true,
    disabled: disableUpload,
  });

  useEffect(() => {
    if (isUploadPanelVisible && selectedFiles.length && !filesToUpload.length) {
      sendMessage('', selectedFiles);
      setSelectedFiles([]);
      setIsUploadPanelVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadPanelVisible, selectedFiles]);

  const { getUrl, url } = GetFileUrl(conversationId, activeDocId);

  useEffect(() => {
    if (url && activeDocId) {
      window.open(url);
      setActiveDocId('');
    }
  }, [url, activeDocId]);

  useEffect(() => {
    if (activeDocId) {
      getUrl();
    }
  }, [activeDocId, getUrl]);

  const openFile: RowClickHandlerType<IDocumentType> = useCallback(
    (item: IDocumentType) => {
      const selection = window.getSelection();
      if (selection?.toString() === '') setActiveDocId(item.id);
    },
    []
  );

  const FilesTableProps = useGetDocumentsTableProps({
    isDragActive,
    conversationId,
    openFile,
  });

  return (
    <LocalErrorBoundary
      catchErrors={[
        HttpStatusCode.else,
        HttpStatusCode.BadRequest,
        HttpStatusCode.NotFound,
      ]}
    >
      <SmartSpinnerLocal
        name="ConversationDocumentsTab"
        condition={!!activeDocId}
        renderAsGlobal={true}
        background={true}
      />
      <div className="w-full h-full relative flex flex-col">
        {!disableUpload && <DocumentToolbar />}
        <div
          {...getRootProps()}
          className={`${classnames(
            'flex flex-col h-full mb-5 flex-0',
            isDragActive
              ? 'bg-gray-100 bg-opacity-90 border-2 border-gray-300 border-dashed'
              : ''
          )}`}
        >
          <FilesTable
            {...FilesTableProps}
            {...{
              isMobileView,
              classNames: {
                header: 'first:indent-8',
                column: 'first:indent-5',
              },
            }}
          />
        </div>
        <ProgressDialog uploading={isUploadPanelVisible} />
      </div>
    </LocalErrorBoundary>
  );
};

export default ConversationDocumentsTab;
