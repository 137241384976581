import {
  CustomerStyles,
  NotificationImage,
  PolicyTable,
  PolicyType,
  Stack,
  PolicyToolkit,
  useStateProvider,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import { useApiInfiniteQuery } from 'src/api';
import { t } from 'i18next';
import { useGetColumns } from './useGetColumns';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

export const PolicyPage = () => {
  const { searchPolicyParams } = useStateProvider().state.customer;
  const queryPolicies = useApiInfiniteQuery(
    'getAllCustomerPolicies',
    [
      {
        activeYear: searchPolicyParams.year,
      },
    ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => subitem as PolicyType)
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const columns = useGetColumns();
  const navigate = useNavigate();
  const isMobileView = useIsSelectedView('sm');

  return (
    <Stack className="w-full h-full relative">
      <Stack
        direction="row"
        className="w-full pt-6 pb-4 px-5"
        alignItems="center"
        justifyContent="between"
      >
        <p className={classnames(CustomerStyles.header)}>
          {t('policies.allPolicies')}
        </p>
      </Stack>
      <div
        className={classnames(
          !isMobileView && 'pb-3 px-5',
          isMobileView && 'pb-3 pl-5'
        )}
      >
        <PolicyToolkit />
      </div>
      <Stack className="w-full h-full relative">
        <Stack className="w-full h-full absolute">
          <PolicyTable
            columns={columns}
            queryPolicies={queryPolicies}
            onRowClick={(row) => {
              navigate(`/policies/${row.id}`);
            }}
            nothingFound={
              <Stack
                className="w-full h-full"
                direction="row"
                alignItems="center"
              >
                <NotificationImage
                  text={
                    searchPolicyParams.year
                      ? t('policies.nothingFiltered')
                      : t('policies.nothingFound')
                  }
                />
              </Stack>
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PolicyPage;
