import {
  Avatar,
  BranchIconList,
  ContactBrokerType,
  IconButton,
  PhoneIcon,
  PlusIcon,
  Stack,
  getInitialsFor,
  styledScrollbar,
  useStateProvider,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';

export const BrokerContactListItem = ({
  item,
  isManager,
}: {
  item: ContactBrokerType;
  isManager?: boolean;
}) => {
  const { userId: id, email, displayName, branchIds = [] } = item;

  const { actions } = useStateProvider();
  const { setShowCreateConversation, setBrokerData: setSelectedContact } =
    actions;

  return (
    <Stack
      direction="col"
      className={classnames(
        'w-[200px] h-[320px] shadow rounded-md relative BrokerContactListItem'
      )}
      onClick={() => {
        setShowCreateConversation(true);
        setSelectedContact({
          id,
          email,
          displayName,
        });
      }}
    >
      {isManager && (
        <Stack alignItems="center" className="absolute -top-4 z-5 w-full">
          <div className="bg-customColor_7 rounded-lg border border-customColor_1 px-3">
            <span className="text-sm font-medium">
              {t('dashboard.contactBrokers.manager')}
            </span>
          </div>
        </Stack>
      )}
      <div
        className={classnames(
          'w-full h-full px-5 py-4 group hover:bg-customColor_2'
        )}
      >
        <div className="w-full h-full group-hover:hidden overflow-hidden">
          <div className="w-[150px] h-[150px]">
            {item.image ? (
              <img
                src={item.image}
                height={150}
                width={150}
                className="rounded-full"
                alt={item.displayName}
              />
            ) : (
              <Avatar
                size="xl"
                classNames="text-3xl"
                initials={getInitialsFor(item.displayName || '')}
              />
            )}
          </div>
        </div>
        <div className="w-full h-full hidden group-hover:block px-4">
          <Stack
            className="w-full h-full text-[#0170df] text-sm font-semibold text-center cursor-pointer"
            direction="col"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton hoverable={false} customSize="h-6 w-6">
              <PlusIcon color="#0170df" />
            </IconButton>
            <p>{t('dashboard.contactBrokers.startConversation')}</p>
          </Stack>
        </div>
      </div>
      <Stack className="w-full min-h-[130px] h-[130px] border-t px-5">
        <p className="leading-tight text-lg font-medium pt-2 text-nowrap truncate">
          {item.displayName}
        </p>
        {item.phoneNumber && (
          <Stack
            direction="row"
            alignItems="center"
            className="gap-1 pt-2 pb-[10px]"
          >
            <IconButton
              hoverable={false}
              className="p-0 m-0"
              customSize="h-4 w-4"
            >
              <PhoneIcon />
            </IconButton>
            <p className="text-xs font-medium">{item.phoneNumber}</p>
          </Stack>
        )}
        <p className="leading-none text-xs font-light">
          {t('dashboard.contactBrokers.branches')}
        </p>
        <div className="w-full overflow-hidden">
          <div
            className={classnames('w-full overflow-x-auto', styledScrollbar)}
          >
            <BranchIconList
              {...{
                branchIds,
              }}
            />
          </div>
        </div>
      </Stack>
    </Stack>
  );
};
