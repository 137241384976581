import {
  Stack,
  IconButton,
  ChevronDownIcon,
  SmartSpinnerLocal,
  ContactBrokerType,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import { MobileBrokerContactListItem } from './MobileBrokerContactListItem';
import classnames from 'classnames';
import { useState } from 'react';
import BrokerContactsModal from './BrokerContactsModal';

export const MobileBrokerContacts = ({
  contacts = [],
}: {
  contacts: ContactBrokerType[];
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Stack className="w-full">
        <p className="text-lg font-semibold py-4">
          {t('dashboard.contactBrokers.title')}
        </p>
        <div
          className="w-full flex flex-col justify-center"
          style={{
            minHeight: 155,
          }}
        >
          <SmartSpinnerLocal
            name="getAvailablePolicyBrokers"
            condition={!contacts.length}
          >
            <MobileBrokerContactListItem
              key={contacts[0]?.displayName}
              {...{ item: contacts[0] }}
            />

            {contacts.length > 1 && (
              <Stack
                direction="row"
                alignItems="center"
                className={classnames(
                  'text-[#0170df] text-base font-medium cursor-pointer pb-4 pt-1'
                )}
                onClick={() => {
                  setShowModal(!showModal);
                }}
              >
                <IconButton className="!m-0 !p-0 !w-5 !mr-1" hoverable={false}>
                  <ChevronDownIcon color="#0170df" />
                </IconButton>
                <p>{t(`dashboard.contactBrokers.showAll`)}</p>
              </Stack>
            )}
          </SmartSpinnerLocal>
        </div>
      </Stack>
      {showModal && (
        <BrokerContactsModal
          {...{
            visible: showModal,
            contacts,
            onClose: () => {
              setShowModal(false);
            },
          }}
        />
      )}
    </>
  );
};

export default MobileBrokerContacts;
