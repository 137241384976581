import {
  Avatar,
  ConversationParticipantType,
  Stack,
  getInitialsFor,
  useUserProfile,
} from '@trueconnect/tp-components';
import { Avatar as AvatarFlowbite } from 'flowbite-react';
import { t } from 'i18next';
import classnames from 'classnames';

export const ParticipantDisplay = ({
  participant,
  participants = [],
}: {
  participant?: ConversationParticipantType;
  participants: ConversationParticipantType[] | undefined;
}) => {
  switch (participants?.length) {
    case undefined:
    case 1:
      return <></>;
    case 2:
      return <TwoMembersLayout {...{ participant }} />;
    default:
      return <MultipleMembersLayout {...{ participants }} />;
  }
};

export default ParticipantDisplay;

const TwoMembersLayout = ({
  participant,
}: {
  participant?: ConversationParticipantType;
}) => {
  return (
    <>
      <Avatar
        title={participant?.displayName}
        subtitle="True Partners AG"
        imgSrc={participant?.image}
        initials={participant?.displayName}
        size="sm"
        classNames="!ml-0 !pl-0 [&>div>img]:h-8 [&>div>img]:w-8 [&>div]:w-8 [&>div]:h-8 [&>div>img]:p-0"
        titleStyles="text-base leading-normal text-black"
        subTitleStyles="font-normal text-sm leading-normal !text-[#6B7280]"
      />
    </>
  );
};

const MultipleMembersLayout = ({
  participants,
}: {
  participants: ConversationParticipantType[];
}) => {
  const participantsToDisplay = 4;
  const profileData = useUserProfile();
  const filteredParticipants = participants.filter(
    (item) => item.userId !== profileData.userId
  );
  return (
    <Stack direction="row" alignItems="center" className="ml-1">
      <AvatarFlowbite.Group className="align-center">
        {filteredParticipants
          ?.slice(0, participantsToDisplay)
          .map((participant, i) => (
            <AvatarFlowbite
              key={participant.userId}
              placeholderInitials={getInitialsFor(
                participant?.displayName || ''
              )}
              img={participant?.image}
              bordered
              stacked
              rounded
              size="sm"
              className={classnames(
                'cursor-default [&>div>div]:!h-8 [&>div>div]:!w-8 [&>div>div]:!ring-0 m-0 p-0 [&>div>div]:shadow',
                '[&>div>img]:w-8 [&>div>img]:h-8 [&>div]:w-8 [&>div]:h-8 [&>div>img]:p-0 [&>div>img]:!ring-0',
                i === 0 ? 'pl-[3px]' : 'pl-1'
              )}
            />
          ))}
      </AvatarFlowbite.Group>
      <h1 className="ml-5">
        {t('conversationPage.amount', { amount: participants.length })}
      </h1>
    </Stack>
  );
};
