import { UserProfile, useGetToken } from '@trueconnect/tp-components';
import { useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { useApiQuery } from 'src/api';
import formatUserPermissions from './formatUserPermissions';
import { loginRequest } from 'src/authConfig';

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  meEndpoint: 'https://graph.microsoft.com/v1.0/me',
  mePhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
  scopes: ['openid', 'profile'],
  redirectUri: '/',
};

const defaultUserProfile: UserProfile = {
  givenName: '',
  surname: '',
  mail: '',
  permissions: {},
};

export const useGetUserProfile = () => {
  const [userProfile, setUserProfile] =
    useState<UserProfile>(defaultUserProfile);

  const { data: userData, refetch } = useApiQuery('ensureUser', [], {
    enabled: false,
  });

  // get access token
  const accessToken = useGetToken({
    ...loginRequest,
  });

  useAsync(async () => {
    if (accessToken && !userData) {
      await refetch();
    }
  }, [accessToken]);

  useEffect(() => {
    setUserProfile((prev) => ({
      ...prev,
      ...userData,
      userId: userData?.id,
      permissions: formatUserPermissions({ list: userData?.permissions }),
    }));
  }, [userData]);

  return { userProfile };
};
