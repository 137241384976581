import {
  CompanyInfo,
  HelpIcon,
  UserPage as UserPageCommon,
  listItem,
} from '@trueconnect/tp-components';
import { useNavigate } from 'react-router-dom';

export const UserPage = () => {
  const navigate = useNavigate();
  const sidebarItems: listItem[] = [
    {
      icon: <HelpIcon color="black" />,
      textKey: 'sidebar.help',
      onClick: () => {
        navigate('/help');
      },
    },
  ];

  return (
    <UserPageCommon {...{ sidebarItems }}>
      <div className="w-full mt-auto [&>div]:pb-2 [&>div]:px-4">
        <CompanyInfo />
      </div>
    </UserPageCommon>
  );
};

export default UserPage;
