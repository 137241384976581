import { useCallback, useEffect } from 'react';
import {
  DownloadDocumentInfoDto,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import { useApiInvalidateQueries, useApiMutation } from 'src/api';
import { t } from 'i18next';

export function useDownloadInvoiceDocument(
  documentId?: string,
  cleadDocumentId?: () => void
) {
  const { addNotification } = useNotificationSystem();
  const invalidateQueries = useApiInvalidateQueries();
  const invalidateQuery = useCallback(async () => {
    await invalidateQueries('getInvoicesByPolicyId');
  }, [invalidateQueries]);
  const { mutate, isLoading } = useApiMutation(
    'gets_Direct_Invoice_Document_Download_Url'
  );

  const handleRefresh = () => {
    invalidateQuery();
  };

  useEffect(() => {
    if (documentId) {
      mutate([documentId], {
        onSuccess: (data) => {
          const result = data as DownloadDocumentInfoDto;
          result.signedUrl && window.open(result.signedUrl, '_blank');
          invalidateQuery();
          cleadDocumentId?.();
        },
        onError: () => {
          addNotification({
            title: t('policyDetails.invoiceResetError'),
            color: 'info',
            onClick: handleRefresh,
            lifeTimeMs: 10000,
          });
          cleadDocumentId?.();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  return { isLoading };
}
