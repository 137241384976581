import {
  ConversationListItemProps,
  ConversationsPage,
  useStateProvider,
  getBranches,
  useNotificationSystem,
  ConversationListItem,
} from '@trueconnect/tp-components';
import { ConversationState, useApiInfiniteQuery } from 'src/api';
import { ConversationContainer } from './ConversationContainer';
import { useParams } from 'react-router-dom';
import ExtendedConversationSearchCustomer from 'src/components/ExtendedConversationSearch';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';
import { t } from 'i18next';
import { useState } from 'react';
import ConversationTopicsSidebar from 'src/components/conversations/ConversationTopicsSidebar';

export const CustomerConversationsPage = () => {
  const { state } = useStateProvider();
  const { addNotification } = useNotificationSystem();
  const { searchValue, conversationFilter, extendedSearchParams } =
    state.conversation;
  const { availableBranches, selectedTopic } = state.workManagement;

  const { id: conversationId } = useParams();

  const {
    selectedContacts,
    dateStart,
    dateEnd,
    selectedBranches,
    selectedTopics,
  } = extendedSearchParams;

  const queryConversations = useApiInfiniteQuery(
    'searchConversations',
    [
      {
        page: 0,
        state:
          conversationFilter === 'All'
            ? undefined
            : (conversationFilter as ConversationState),
        search: searchValue,
        brokerUserIds: selectedContacts,
        dateCreatedFrom: dateStart,
        dateCreatedTo: dateEnd,
        branchIds: selectedBranches,
        topicIds: [
          ...(selectedTopic.id ? [selectedTopic.id] : []),
          ...(selectedTopics || []),
        ],
      },
    ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map(
            (subitem) =>
              ({
                title: subitem.title,
                id: subitem.id,
                participants: subitem.participants,
                lastActive: subitem.lastActivityUtcTimestamp,
                branches: getBranches(availableBranches, subitem.branchIds),
                ...subitem,
              } as ConversationListItemProps)
          )
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const [searchParam, setSearchParam] = useState('');
  const queryTopics = useApiInfiniteQuery(
    'searchTopics',
    [{ search: searchParam, page: 0 }],
    {
      onError: () => {
        addNotification({
          color: 'failure',
          title: t('Topics.Sidebar.error'),
        });
      },
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map((subitem) => subitem as TopicType)
        ),
    }
  ) as UseInfiniteQueryResult<TopicType[], Error>;

  const hasConversations =
    queryConversations.data && queryConversations.data.length > 0;

  return (
    <ConversationsPage
      isSearching={!!searchValue}
      queryConversations={queryConversations}
      ExtendedConversationSearch={<ExtendedConversationSearchCustomer />}
      rowRenderer={(item) => {
        return (
          <ConversationListItem
            key={item.id}
            {...item}
            selected={conversationId === item.id}
          />
        );
      }}
      TopicsSearch={
        <ConversationTopicsSidebar
          {...{ queryTopics, setSearchParam, searchParam }}
        />
      }
    >
      <ConversationContainer
        isSearching={!!searchValue}
        isLoading={!!queryConversations.isLoading}
        hasConversations={hasConversations}
        conversationId={conversationId}
      />
    </ConversationsPage>
  );
};

export default CustomerConversationsPage;
