import { useApiQuery } from 'src/api';

export const GetFileUrl = (conversationId: string, documentId: string) => {
  const { data: fileUrl, refetch } = useApiQuery(
    'gets_Direct_Conversation_Document_Download_Url',
    [conversationId, documentId],
    {
      enabled: false,
    }
  );

  return {
    getUrl: () => {
      refetch();
    },
    url: fileUrl?.signedUrl,
  };
};

export default GetFileUrl;
