import {
  ConversationListItemProps,
  Dashboard as DashboardCommon,
  getBranches,
  useStateProvider,
  useUserProfile,
} from '@trueconnect/tp-components';
import { ConversationState, useApiInfiniteQuery } from 'src/api';
import BrokerContacts from 'src/components/dashboard/BrokerContacts/BrokerContacts';

export const Dashboard = () => {
  const { displayName, permissions } = useUserProfile();
  const { availableBranches } = useStateProvider().state.workManagement;

  const queryConversations = useApiInfiniteQuery(
    'searchConversations',
    [
      {
        state: ConversationState.Open,
        search: '',
      },
    ],
    {
      select: (data) =>
        data.pages.flatMap((item) =>
          item.map(
            (subitem) =>
              ({
                title: subitem.title,
                id: subitem.id,
                participants: subitem.participants,
                lastActive: subitem.lastActivityUtcTimestamp,
                branches: getBranches(availableBranches, subitem.branchIds),
                ...subitem,
              } as ConversationListItemProps)
          )
        ),
      refetchOnWindowFocus: false,
      enabled: permissions?.allowConversations,
    }
  );
  return (
    <DashboardCommon
      displayName={displayName}
      permissions={permissions}
      queryConversations={queryConversations}
    >
      <BrokerContacts />
    </DashboardCommon>
  );
};
