import {
  ConversationsIcon,
  DocumentIcon,
  MessageType,
  TabProps,
  Tabs,
  useIsSelectedView,
  UsersIcon,
  useStateProvider,
} from '@trueconnect/tp-components';
import { useTranslation } from 'react-i18next';
import { ConversationDocumentsTab, ConversationTab } from './Tabs';
import { AttachmentDto } from 'src/api';
import { Dispatch, SetStateAction } from 'react';
import classnames from 'classnames';
import ConversationMembersTab from './Tabs/ConversationMembersTab/ConversationMembersTab';

export type ConversationTabsProps = {
  userId?: string;
  sendMessage: (message: string, attachments?: AttachmentDto[]) => void;
  messages: MessageType[];
  setMessages: Dispatch<SetStateAction<MessageType[]>>;
  lastReadMessagesTimestamp?: Date;
};

export const ConversationTabs: React.FC<ConversationTabsProps> = (props) => {
  const { t } = useTranslation();
  const { conversationId, participants: members = [] } =
    useStateProvider().state.conversation.conversationData;
  const isMobileView = useIsSelectedView('sm');

  const tabs: TabProps[] = [
    {
      path: `/conversations/${conversationId}`,
      title: t('tab.conversations'),
      icon: <ConversationsIcon />,
      component: <ConversationTab {...props} />,
    },
    {
      path: `/conversations/${conversationId}/files`,
      title: t('tab.files'),
      icon: <DocumentIcon />,
      component: <ConversationDocumentsTab {...props} />,
    },
    {
      path: `/conversations/${conversationId}/members`,
      title: t('tab.members'),
      icon: <UsersIcon />,
      testId: 'membersTab',
      component: (
        <ConversationMembersTab {...{ ...props, conversationId, members }} />
      ),
    },
  ];

  return (
    <Tabs
      tabs={tabs}
      className={classnames(
        '!mx-0 [&>div]:gap-8 [&>div>button]:px-0',
        isMobileView ? 'flex-nowrap overflow-auto' : '!overflow-hidden',
        isMobileView ? '[&>*:first-child]:!pl-4' : '[&>*:first-child]:!pl-8',
        "[&>div[role='tablist']>button]:!px-0"
      )}
    />
  );
};

export default ConversationTabs;
