import {
  Stack,
  hoverClassname,
  IconButton,
  DocumentIcon,
  ArrowDownTrayIcon,
  DownloadDocumentInfoDto,
  Spinner,
  useNotificationSystem,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';
import { GetPolicyByIdDto, useApiMutation } from 'src/api';

export const PolicyDocument = ({
  policyData,
}: {
  policyData: GetPolicyByIdDto;
}) => {
  const { addNotification } = useNotificationSystem();
  const { mutate, isLoading } = useApiMutation(
    'gets_Direct_Policy_Document_Download_Url'
  );
  const { id: policyId, documentId } = policyData;

  const downloadPolicyPDF = () => {
    mutate([policyId], {
      onSuccess: (data) => {
        window.open((data as DownloadDocumentInfoDto).signedUrl, '_blank');
      },
      onError: () => {
        addNotification({
          title: t('policyDetails.downloadError'),
          color: 'failure',
        });
      },
    });
  };
  if (!documentId) {
    return <></>;
  }

  return (
    <Stack
      direction="row"
      dataTestid="downloadPolicyDocument"
      alignItems="center"
      justifyContent="between"
      className={classnames(
        'w-full h-[56px] min-h-[56px] px-[30px] bg-customColor_7 border-b',
        hoverClassname
      )}
      onClick={() => {
        !isLoading && downloadPolicyPDF();
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        className="text-base font-medium"
      >
        <IconButton hoverable={false} className="!m-0 !p-0 w-[20px] !mr-2">
          <DocumentIcon />
        </IconButton>
        <p>{t('policyDetails.policyIcon')}</p>
      </Stack>

      {isLoading ? (
        <Spinner />
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          className={classnames('text-[#1C64F2] p-1 rounded-lg')}
        >
          <p className="text-nowrap">{t('policyDetails.download')}</p>
          <IconButton hoverable={false} className="!m-0 !p-0 w-[20px] !ml-2">
            <ArrowDownTrayIcon color="#1C64F2" />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default PolicyDocument;
