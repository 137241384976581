import { Route, Routes as Router } from 'react-router-dom';
import { CustomerConversationsPage } from './Conversations';
import { Dashboard } from './Dashboard/Dashboard';
import { CustomerDocumentPage } from './CustomerDocument';
import { Help } from './Help';
import PolicyPage from './Policies/PolicyPage';
import PoliceDetails from './Policies/PolicyDetails';
import UserPage from './Users/UserPage';

const Routes = () => {
  return (
    <Router>
      <Route path="/" element={<Dashboard />} />
      <Route path="/me" element={<UserPage />} />
      <Route path="/conversations" element={<CustomerConversationsPage />}>
        <Route path=":id" element={<CustomerConversationsPage />}>
          <Route path=":tab" element={<CustomerConversationsPage />} />
        </Route>
      </Route>
      <Route path="/documents" element={<CustomerDocumentPage />} />
      <Route path="/policies" element={<PolicyPage />} />
      <Route path="/policies/:id" element={<PoliceDetails />} />
      <Route path="/help" element={<Help />} />
      <Route path="/help" element={<Help />}>
        <Route path=":value" element={<Help />} />
      </Route>
    </Router>
  );
};

export default Routes;
