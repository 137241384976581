import {
  Avatar,
  BranchIconList,
  IconButton,
  PhoneIcon,
  Stack,
  getInitialsFor,
  hiddenScrollbar,
  hoverClassname,
  ContactBrokerType,
  useStateProvider,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { t } from 'i18next';

export const MobileBrokerContactListItem = ({
  item,
}: {
  item: ContactBrokerType;
}) => {
  const {
    userId: id,
    email,
    displayName = '',
    branchIds = [],
    isManager,
  } = item;

  const { actions } = useStateProvider();
  const { setShowCreateConversation, setBrokerData: setSelectedContact } =
    actions;

  return (
    <Stack
      direction="row"
      className={classnames(
        'w-full h-[110px] px-5 py-2 shadow rounded-md relative',
        hoverClassname
      )}
      onClick={() => {
        setShowCreateConversation(true);
        setSelectedContact({
          id,
          email,
          displayName,
        });
      }}
    >
      <div className="w-[80px] h-[80px] mr-5 my-auto">
        {item.image ? (
          <img
            src={item.image}
            height={80}
            width={80}
            className="rounded-full"
            alt={displayName}
          />
        ) : (
          <Avatar
            size="lg"
            classNames="text-xl m-0 p-0"
            bordered={true}
            initials={getInitialsFor(displayName || '')}
          />
        )}
      </div>
      {isManager && (
        <Stack
          alignItems="center"
          className="absolute -top-4 left-0 z-5 w-full"
        >
          <div className="bg-customColor_7 rounded-lg border border-customColor_1 px-3">
            <span className="text-sm font-medium">
              {t('dashboard.contactBrokers.manager')}
            </span>
          </div>
        </Stack>
      )}
      <Stack
        direction="col"
        justifyContent="center"
        className="h-full [&>div:nth-child(2)]:py-0 overflow-hidden"
      >
        <p className="leading-tight text-lg font-medium">{displayName}</p>
        {item.phoneNumber && (
          <Stack direction="row" alignItems="center" className="gap-1 pt-2">
            <IconButton className="p-0 m-0" customSize="h-4 w-4">
              <PhoneIcon />
            </IconButton>
            <p className="text-xs font-medium pt-1">{item.phoneNumber}</p>
          </Stack>
        )}
        <div className={classnames('w-full overflow-x-auto', hiddenScrollbar)}>
          <BranchIconList
            {...{
              branchIds,
            }}
          />
        </div>
      </Stack>
    </Stack>
  );
};
