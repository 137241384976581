import {
  ApiMutationFunctions,
  ApiQueryFunctions,
  CustomerAppLogicClient,
} from './CustomerAppLogic';
import { ApiOptions } from '@trueconnect/tp-components';

/** Defines all default options for useApiQuery and useApiMutation
 */
export const defaultApiOptions: ApiOptions<
  CustomerAppLogicClient,
  ApiMutationFunctions,
  ApiQueryFunctions
> = {
  getConversationById: (
    options,
    [id],
    invalidateQueries,
    addNotification,
    t
  ) => ({
    ...options,
    enabled: options.enabled || !!id,
    errorMessage: t('apiCalls.error.openConversation'),
  }),

  /** This is used for all useApiMutation */
  defaultMutation: (
    methodName,
    options,
    invalidateQueries,
    addNotification,
    t
  ) => ({
    ...options,
    onError: (error: unknown) =>
      addNotification({
        title: options.errorMessage || t('apiMutationOptions.error.default'),
        color: 'failure',
        subTitle: `${(error as Error)?.message || error} (${methodName})`,
        lifeTimeMs: 10000,
      }),
  }),

  /** This is used for all useApiQuery */
  defaultQuery: (
    methodName,
    args,
    options,
    invalidateQueries,
    addNotification,
    t
  ) => ({
    ...options,
    onError: (error: unknown) =>
      addNotification({
        title: options.errorMessage || t('apiQueryOptions.error.default'),
        color: 'failure',
        subTitle: `${(error as Error)?.message || error} in ${String(
          methodName
        )}`,
        lifeTimeMs: 10000,
      }),
  }),
};

export default defaultApiOptions;
