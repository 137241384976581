import React, { Suspense } from 'react';
import {
  AppPage,
  BottomBar,
  CompanyInfo,
  Sidebar,
  useConfig,
  useUserProfile,
} from '@trueconnect/tp-components';
import Routes from 'src/pages/App/AppRoutes';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import UserNavbar from './../../components/UserNavbar';
import { useSidebarConfig } from './../../components/SidebarConfig';

const runReactQueryDevTools = false;

export const App: React.FC = () => {
  const { permissions } = useUserProfile();
  const sidebarConfig = useSidebarConfig({ permissions });
  const bottomBarConfig = sidebarConfig.flat(1);
  const { FullSidebar } = useConfig().configState;

  return (
    <Suspense>
      <AppPage>
        <AppPage.Header>
          <UserNavbar />
        </AppPage.Header>
        <AppPage.Sidebar>
          <Sidebar groups={sidebarConfig}>
            {FullSidebar && <CompanyInfo />}
          </Sidebar>
        </AppPage.Sidebar>
        <AppPage.Content>
          <Routes />
        </AppPage.Content>
        <AppPage.BottomBar>
          <BottomBar list={bottomBarConfig} />
        </AppPage.BottomBar>
      </AppPage>
      {runReactQueryDevTools && <ReactQueryDevtools initialIsOpen={false} />}
    </Suspense>
  );
};

export default App;
