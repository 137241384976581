import {
  Stack,
  Avatar,
  textStyle_medium,
  textStyle_xs,
  ConversationParticipantType,
  CustomerStyles,
} from '@trueconnect/tp-components';
import classnames from 'classnames';

export const ContactListItem = (item: ConversationParticipantType) => {
  const { displayName: name, email, image, phone, activeInTrueData } = item;
  const hasContactEmail = !!email;

  return (
    <Stack
      direction="row"
      alignItems="center"
      className={classnames(
        'contact truncate',
        CustomerStyles.greyedOut(!(activeInTrueData || hasContactEmail))
      )}
    >
      <Avatar
        imgSrc={image}
        initials={name}
        size="xs"
        classNames="mr-2 ml-0 m-0 p-0 mr-2 ml-1 !w-8 !h-8 [&>div>img]:w-8 [&>div>img]:h-8 [&>div]:w-8 [&>div]:h-8"
      />
      <Stack className="pl-2 pb-1">
        <Stack alignItems="center" direction="row">
          <h1 className={textStyle_medium}>{name}</h1>
        </Stack>
        {email && <p className={textStyle_xs}>{email}</p>}
        {phone && <p className={textStyle_xs}>{phone}</p>}
      </Stack>
    </Stack>
  );
};

export default ContactListItem;
