import {
  Stack,
  textStyle_5,
  useIsSelectedView,
} from '@trueconnect/tp-components';
import classnames from 'classnames';

export const SelectedCompany = ({ name }: { name: string }) => {
  const isMobileView = useIsSelectedView('sm');

  return (
    <Stack
      direction="row"
      className={classnames(
        isMobileView ? 'text-center leading-none mr-auto' : '',
        'overflow-hidden'
      )}
    >
      <p className={classnames(textStyle_5, 'truncate px-2')}>{name}</p>
    </Stack>
  );
};

export default SelectedCompany;
