import {
  Stack,
  CommonStyles,
  SearchInput,
  VirtualizedList,
  CreateConversationPanel,
  useStateProvider,
  truncateString,
  useNotificationSystem,
  IconButton,
  XCircleIcon,
  Button,
  TextInput,
} from '@trueconnect/tp-components';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useApiInfiniteQuery,
  useApiInvalidateQueries,
  useApiMutation,
} from 'src/api';
import Contact from './conversations/Contact';
import { useNavigate } from 'react-router-dom';

type BrokerContactType = {
  id: string;
  email: string;
  displayName: string;
  image?: string;
  imageId?: string;
};

export function CreateConversationPanelCustomer() {
  const invalidateQueries = useApiInvalidateQueries();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { addNotification } = useNotificationSystem();
  const { state, actions } = useStateProvider();
  const { setShowCreateConversation, setBrokerData: setSelectedContact } =
    actions;
  const { brokerData: selectedContact } = state.customer;

  const [searchParam, setSearchParam] = useState('');
  const [conversationName, setConversationName] = useState('');

  const queryContacts = useApiInfiniteQuery('searchBrokers', [searchParam, 0], {
    select: (data) =>
      data.pages.flatMap((item) =>
        item.map(
          (subitem) =>
            ({
              id: subitem.id,
              email: subitem.email,
              displayName: subitem.displayName,
              imageId: subitem.imageId,
            } as BrokerContactType)
        )
      ),
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const { refetch } = queryContacts;
  const { showCreateConversation } = useStateProvider().state.conversation;

  useEffect(() => {
    showCreateConversation && refetch();
  }, [refetch, showCreateConversation, searchParam]);

  const { mutate, isLoading: isCreatingConversation } = useApiMutation(
    'create_Conversation'
  );

  const createConversation = (subject: string) => {
    selectedContact.id &&
      mutate(
        [
          {
            brokerUserId: selectedContact.id.toString(),
            title: truncateString({
              str: subject,
            }),
          },
        ],
        {
          onSuccess: (conversationId) => {
            invalidateQueries('searchConversations');
            setShowCreateConversation(false);
            setSelectedContact({});
            navigate(`/conversations/${conversationId}`);
          },
          onError: () => {
            addNotification({
              title: t('apiCalls.error.createConversation'),
              color: 'failure',
            });
          },
        }
      );
  };

  const onClose = () => {
    setShowCreateConversation(false);
    setSelectedContact({});
    setSearchParam('');
  };

  return (
    <CreateConversationPanel
      {...{
        isCreatingConversation,
        close: onClose,
      }}
      header={
        <>
          {selectedContact.id && (
            <Stack direction="col" className="w-full">
              <Stack direction="col" className="w-full my-6">
                <label className="text-[#0170df] block text-xs font-medium leading-3">
                  {t('navbar.createConversationPanel.titleLable')}
                </label>
                <TextInput
                  value={conversationName}
                  placeholder={t('conversation.subjectPlaceholder')}
                  className="border-b border-[#0170df] h-10 w-full focus:outline-none focus:border-blue-700 [&>div>input]:px-0 [&>div>input]:!bg-transparent [&>div>input:focus]:outline-none [&>div>input]:border-none [&>div>input]:rounded-none"
                  onChange={(e) => {
                    setConversationName(e.target.value);
                  }}
                />
              </Stack>
              <Stack
                className="h-[71px] mb-8 w-full border-b"
                direction="row"
                alignItems="center"
              >
                <Contact
                  {...{
                    data: selectedContact,
                    searchParam: '',
                  }}
                />
                <IconButton
                  onClick={() => {
                    setSelectedContact({});
                  }}
                >
                  <XCircleIcon className="h-5" />
                </IconButton>
              </Stack>
            </Stack>
          )}
          {!selectedContact.id && (
            <>
              <SearchInput
                classNames={{ wrapper: 'h-[52px] my-6' }}
                setSearchParam={setSearchParam}
                saveHistory={true}
                inputName="CreateConversationSearch"
                placeholder={
                  t('navbar.createConversationPanel.inputPlaceholder') as string
                }
                dataTestid="createConversationSearchInput"
              />
              <h1
                className={classnames(
                  'font-light text-sm	text-customColor_4 font-normal leading-normal mb-0'
                )}
              >
                {t('navbar.createConversationPanel.startQuestion')}
              </h1>
            </>
          )}
        </>
      }
    >
      <>
        {!selectedContact.id && (
          <Stack
            direction="col"
            alignItems="stretch"
            className={CommonStyles.full}
          >
            <VirtualizedList
              columns={[
                {
                  dataPropName: 'contact',
                  render: (contact) => (
                    <Contact
                      {...{
                        data: contact,
                        searchParam,
                      }}
                    />
                  ),
                },
              ]}
              onRowClick={(item) => {
                setSelectedContact(item);
              }}
              rowHeight={71}
              infinityQuery={queryContacts}
              headerHeight={0}
            />
          </Stack>
        )}
        {selectedContact.id && (
          <Stack
            className="w-full mt-auto gap-3 px-2"
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              className="w-full"
              dataTestid="cancelCreateConversation"
              onClick={onClose}
            >
              {t('common.cancel')}
            </Button>
            <Button
              className="w-full"
              dataTestid="acceptCreateConversation"
              variant="primary"
              disabled={!conversationName || !selectedContact.id}
              onClick={() => {
                createConversation(conversationName);
              }}
            >
              {t('common.accept')}
            </Button>
          </Stack>
        )}
      </>
    </CreateConversationPanel>
  );
}

export default CreateConversationPanelCustomer;
