import {
  VirtualizedGridCellRendererType,
  IDocumentType,
  ModifiedBy,
  useIsSelectedView,
  useGetDocumentsColumns,
  FileInfo,
} from '@trueconnect/tp-components';
import { More } from './columns';
import { useTranslation } from 'react-i18next';

export const useGetColumns = ({
  downloadFile,
}: {
  downloadFile: (id: string) => void;
}) => {
  const { t } = useTranslation();

  const defaultColumns = useGetDocumentsColumns();

  const columns: VirtualizedGridCellRendererType<IDocumentType>[] = [
    ...defaultColumns,
    {
      dataPropName: 'modifiedBy',
      title: t('file.modifiedBy'),
      render: ModifiedBy,
      widthRatio: 7,
    },
    {
      dataPropName: 'more',
      title: t('file.more'),
      render: (item) =>
        item.type === 'file' ? (
          <More item={item} downloadFile={downloadFile} />
        ) : (
          <></>
        ),
      widthRatio: 2,
    },
  ];

  const isMobileView = useIsSelectedView('sm');
  const isTabletView = useIsSelectedView('md');
  const isLargeView = useIsSelectedView('lg');

  if (isMobileView) {
    return [
      FileInfo,
      ...columns.filter(
        (item) =>
          !['fileName', 'modifiedAt', 'createdBy', 'modifiedBy'].includes(
            item.dataPropName
          )
      ),
    ];
  }

  if (isTabletView) {
    return columns.filter(
      (item) => !['modifiedAt', 'modifiedBy'].includes(item.dataPropName)
    );
  }

  if (isLargeView) {
    return columns.filter(
      (item) => !['modifiedBy'].includes(item.dataPropName)
    );
  }

  return columns;
};

export default useGetColumns;
