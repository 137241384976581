import { ContactPermissionDto } from '@trueconnect/tp-components';
import { UserPermissionType } from 'src/api';

export const formatUserPermissions = ({
  list,
}: {
  list?: UserPermissionType[] | undefined;
}) => {
  if (!list) {
    return {};
  }

  return {
    allowConversations: list.includes(
      'ConversationsAccess' as UserPermissionType
    ),
    allowSharedDocuments: list.includes(
      'SharedDocumentsAccess' as UserPermissionType
    ),
    allowPolicyDirectory: list.includes(
      'PolicyDirectoryAccess' as UserPermissionType
    ),
  } as ContactPermissionDto;
};

export default formatUserPermissions;
