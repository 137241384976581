import { useEffect, useState } from 'react';
import {
  Stack,
  DetailsHeader,
  SmartSpinnerLocal,
  useIsSelectedView,
  defaultScrollbar,
} from '@trueconnect/tp-components';
import { useParams } from 'react-router-dom';
import { useApiQuery } from 'src/api';
import PolicyDocument from './PolicyDocument';
import PolicyData from './PolicyData';
import PolicyInvoiceTable from './Invoices/PolicyInvoiceTable';
import { useDownloadInvoiceDocument } from './Invoices/useDownloadInvoiceDocument';
import classnames from 'classnames';

export const PolicyDetails = () => {
  const { id = '' } = useParams();
  const isMobileView = useIsSelectedView('sm');

  const { data: policyData, isLoading } = useApiQuery('getPolicyById', [id], {
    enabled: !!id,
  });

  const [documentInvoiceId, setDocumentInvoiceId] = useState<string>();

  const { isLoading: isDocumentInvoiceLoading } =
    useDownloadInvoiceDocument(documentInvoiceId);

  useEffect(() => {
    const url = window.location.href.replace('#?', '?');
    const urlParams = new URLSearchParams(url.split('?')[1]);
    const downloadParam = urlParams.get('download');

    downloadParam && setDocumentInvoiceId(downloadParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  if (!policyData?.id || !id) {
    return <></>;
  }

  return (
    <Stack className="w-full h-full relative">
      <Stack
        className={classnames(
          'w-full h-full absolute bottom-0 left-0 right-0 top-0 overflow-y-auto',
          defaultScrollbar,
          !isMobileView && 'py-6 px-5'
        )}
      >
        <SmartSpinnerLocal
          condition={isLoading || isDocumentInvoiceLoading}
          name="getPolicyById"
        >
          <DetailsHeader
            {...{
              policyData,
              returnUrl: `/policies`,
              classNames: classnames(
                '[&>div>div]:top-3 !pt-0',
                isMobileView && 'pb-2'
              ),
            }}
          />
          <PolicyDocument {...{ policyData }} />
          <PolicyData {...{ policyData }} />

          <PolicyInvoiceTable
            {...{
              policyId: policyData.id,
            }}
          />
        </SmartSpinnerLocal>
      </Stack>
    </Stack>
  );
};

export default PolicyDetails;
