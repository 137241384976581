import { Avatar, Highlighted, Stack } from '@trueconnect/tp-components';
import { useState, useEffect } from 'react';
import { SearchBrokerResponseDto } from 'src/api';
import { getContactImage } from 'src/utils';
import classnames from 'classnames';

interface ContactProps {
  data: SearchBrokerResponseDto;
  searchParam: string;
}

export const Contact: React.FC<ContactProps> = ({ data, searchParam }) => {
  const { email, displayName, imageId } = data;

  const [image, setImage] = useState<string>('');

  useEffect(() => {
    (async () => {
      setImage(await getContactImage(imageId));
    })();
  }, [imageId]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="between"
      className="w-full"
      dataTestid={`contact_${data.email?.replace('@', '_')}`}
    >
      <Stack direction="row" alignItems="center">
        <Avatar
          initials={`${displayName}`}
          imgSrc={image}
          size="xs"
          classNames={classnames(
            'ml-0 pl-0 bg-none [&>div>div]:bg-transparent',
            'm-0 p-0 mr-2 ml-1 !w-8 !h-8 [&>div>img]:w-8 [&>div>img]:h-8 [&>div]:w-8 [&>div]:h-8'
          )}
        />
        <Stack className="pl-2 pb-1">
          <h1 className="font-medium cursor-pointer">
            {Highlighted(displayName, searchParam)}
          </h1>
          <h2 className="text-xs text-customColor_4 leading-normal">
            {Highlighted(email, searchParam)}
          </h2>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Contact;
