import { InvoiceTable, useIsSelectedRange } from '@trueconnect/tp-components';
import { useApiQuery } from 'src/api';
import { useGetInvoiceColumns } from './useGetColumns';

export const PolicyInvoiceTable = ({ policyId }: { policyId: string }) => {
  const columns = useGetInvoiceColumns();
  const isMobileView = useIsSelectedRange(['sm', 'md']);

  const { data = [], isLoading } = useApiQuery(
    'getInvoicesByPolicyId',
    [policyId],
    {
      enabled: !!policyId,
    }
  );

  return (
    <InvoiceTable
      {...{
        data,
        isLoading,
        columns,
        isMobileView,
      }}
    />
  );
};

export default PolicyInvoiceTable;
