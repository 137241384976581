import axios from 'axios';
import { CustomerAppLogicClient } from './CustomerAppLogicClient.generated';
import {
  ApiInfiniteQueryPageMapping,
  KeysMatching,
} from '@trueconnect/tp-components';

const server = process.env.REACT_APP_API_URL || '';

export * from './CustomerAppLogicClient.generated';

/** Singelton API Client for CustomerAppLogic  */
export const CustomerAppLogic = new CustomerAppLogicClient(server, axios);

type ApiVoidFunctions = {
  [P in keyof CustomerAppLogicClient]: Awaited<
    ReturnType<CustomerAppLogicClient[P]>
  > extends void
    ? true
    : false;
};

/**
 * All mutation methods on BrokerAppLogic.
 * NOTE: Please extend when new update methods are added.
 */
export type ApiMutationFunctions =
  | KeysMatching<ApiVoidFunctions, true>
  | 'send_Message'
  | 'create_Conversation'
  | 'set_Participant_Conversation_Messages_Last_Read_Time'
  | 'get_Document_Customer_Hierarchy_slice'
  | 'getBrokerImage'
  | 'gets_Direct_Policy_Document_Download_Url'
  | 'gets_Direct_Invoice_Document_Download_Url';

/** All Query methods on BrokerAppLogic */
export type ApiQueryFunctions = Exclude<
  keyof CustomerAppLogicClient,
  ApiMutationFunctions
>;

export const queryPageMapping: ApiInfiniteQueryPageMapping<
  CustomerAppLogicClient,
  ApiQueryFunctions
> = {
  getMessages: (page, conversationId, p, cancelToken) => [
    conversationId,
    page,
    cancelToken,
  ],
  searchConversations: (p, body, cancelToken) => {
    return [{ ...body, page: p }, cancelToken];
  },
  searchBrokers: (page, search, p, cancelToken) => [search, page, cancelToken],
  getAllCustomerPolicies: (p, cancelToken) => {
    return [cancelToken];
  },
  searchTopics: (p, body, cancelToken) => {
    return [{ ...body, page: p }, cancelToken];
  },
};
