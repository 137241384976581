import {
  Help as HelpCommon,
  IconButton,
  PhoneIcon,
  Stack,
  getContextUrl,
  useIsSelectedRange,
} from '@trueconnect/tp-components';
import { HelpType } from '@trueconnect/tp-components/src/pages/Help';
import docs from './help.json';
import { t } from 'i18next';
import classnames from 'classnames';

const HeaderComponent = () => {
  const phoneNumber = '+41 (0)52 354 25 25';
  const isSelectedView = useIsSelectedRange(['sm', 'md', 'lg']);

  return (
    <div className={classnames('max-w-[400px]', !isSelectedView && 'mr-10')}>
      <p className="font-bold mb-[10px]">{t('settings.anyQuestions')}</p>
      <p>{t('settings.subtitle.questions')}</p>
      <Stack direction="row" alignItems="center" className="mt-2">
        <IconButton hoverable={false} className="!-ml-1 !pl-0">
          <PhoneIcon color="black" className="ml-0" />
        </IconButton>
        <a href={`tel: ${phoneNumber}`} className="truncate">
          {phoneNumber}
        </a>
      </Stack>
    </div>
  );
};

export const Help = () => {
  return (
    <HelpCommon
      data={(docs as HelpType[]).map((item) => ({
        ...item,
        contextURL: getContextUrl(item),
      }))}
      headerComponent={<HeaderComponent />}
    />
  );
};

export default Help;
