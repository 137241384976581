import {
  Stack,
  CustomerStyles,
  Avatar,
  customerContacts,
  ConversationParticipantType,
} from '@trueconnect/tp-components';
import classnames from 'classnames';

export const ContactData = (
  item: ConversationParticipantType,
  className?: string,
  avatarClassName?: string
) => {
  const { image, displayName, accessSuspended = false } = item;

  return (
    <Stack
      direction="row"
      alignItems="center"
      className={classnames(
        CustomerStyles.greyedOut(accessSuspended),
        'ml-2 overflow-hidden',
        className
      )}
      dataTestid={item.email}
    >
      <Avatar
        imgSrc={image}
        initials={displayName}
        size="xs"
        classNames={classnames(
          'mr-2 ml-0 m-0 p-0 mr-2 ml-1 !w-8 !h-8 [&>div>img]:w-8 [&>div>img]:h-8 [&>div]:w-8 [&>div]:h-8',
          avatarClassName
        )}
      />
      <p
        className={classnames(
          customerContacts,
          'truncate w-full  font-[inter]'
        )}
      >
        {displayName}
      </p>
    </Stack>
  );
};
