import {
  useIsSelectedRange,
  EditableTitle,
  useIsSelectedView,
  RedirectButton,
  Stack,
  ConversationParticipantType,
  useStateProvider,
  EllipsisHorizontalIcon,
  IconButton,
} from '@trueconnect/tp-components';
import ParticipantDisplay from './ParticipantDisplay';

export const ConversationHeader = ({
  title,
  participant,
}: {
  title: string;
  participant?: ConversationParticipantType;
}) => {
  const isMobileView = useIsSelectedView('sm');
  const isSelectedView = useIsSelectedRange(['sm', 'md', 'lg']);

  const { conversationData } = useStateProvider().state.conversation;
  const { participants } = conversationData;

  return (
    <Stack
      direction="row"
      className=" w-full"
      alignItems="center"
      justifyContent="between"
    >
      {isSelectedView && participants?.length ? (
        <>
          {isMobileView && <RedirectButton path="/conversations" />}
          <Stack direction="col" className="w-full">
            <h1
              className={
                'font-medium text-xl leading-normal truncate flex-1 w-0 min-w-full'
              }
            >
              {title}
            </h1>
            <ParticipantDisplay {...{ participants, participant }} />
          </Stack>
        </>
      ) : (
        <EditableTitle title={title || ''} isEditable={false} />
      )}
      {/*to make customer layout be like broker one  */}
      <Stack direction="row" className="mr-1 invisible">
        <IconButton>
          <EllipsisHorizontalIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default ConversationHeader;
