import {
  Button,
  ConversationParticipantType,
  IconButton,
  Stack,
  XMarkIcon,
  useStateProvider,
} from '@trueconnect/tp-components';
import ParticipantDisplay from './ParticipantDisplay';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

type UserActionsProps = {
  participant?: ConversationParticipantType;
};

export const UserActions: React.FC<UserActionsProps> = ({ participant }) => {
  const { conversationData } = useStateProvider().state.conversation;
  const { participants } = conversationData;
  const { t } = useTranslation();

  return (
    <Stack
      className="w-full justify-between my-[10px] h-10"
      direction="row"
      alignItems="center"
      justifyContent="between"
    >
      <Stack>
        <ParticipantDisplay {...{ participants, participant }} />
      </Stack>
      {conversationData.state === 'Closed' && (
        <Button
          className={classnames(
            'border border-2 h-[34px] !p-0 mr-[3px] cursor-pointer [&>span]:!py-2'
          )}
          variant="secondary"
          dataTestid="ConversationClosedBadge"
        >
          <IconButton
            size="none"
            hoverable={false}
            className="!w-4 !h-4 mr-1"
            imgClassName="mx-0"
          >
            <XMarkIcon />
          </IconButton>
          <p className="text-xs">{t('conversationPage.ClosedStatus')}</p>
        </Button>
      )}
    </Stack>
  );
};
