import {
  Stack,
  dateFormat,
  currencyFormat,
  useStateProvider,
  PolicyContacts,
  useIsSelectedRange,
} from '@trueconnect/tp-components';
import { t } from 'i18next';
import classnames from 'classnames';
import { GetPolicyByIdDto } from 'src/api';
import { useState, useEffect } from 'react';
import { getContactImage } from 'src/utils';

const headerStyle = 'text-base font-semibold';
const textStyle = 'font-medium text-customColor_4 mb-[2px] truncate';

export const PolicyData = ({
  policyData,
}: {
  policyData: GetPolicyByIdDto;
}) => {
  const { actions } = useStateProvider();
  const isMobileView = useIsSelectedRange(['sm', 'md', 'lg']);
  const { setBrokerData, setShowCreateConversation } = actions;

  const policyManager = policyData?.policyManager;
  const accountManager = policyData?.accountManager;

  const [policyManagerImage, setPolicyManagerImage] = useState('');
  const [accountManagerImage, setAccountManagerImage] = useState('');

  useEffect(() => {
    if (policyData) {
      const fetchImages = async () => {
        const [policyImage, accountImage] = await Promise.all([
          getContactImage(policyManager?.imageId),
          getContactImage(accountManager?.imageId),
        ]);

        setPolicyManagerImage(policyImage);
        setAccountManagerImage(accountImage);
      };

      fetchImages();
    }
  }, [accountManager?.imageId, policyManager?.imageId, policyData]);

  const {
    expirationDate,
    notes = 'Ardevia',
    bruttoPremium = 4000,
    insuranceCompanyLocalAddress: localAddress,
    insuranceCompanyZipCode: zipCode,
    insuranceCompanyCity: city,
  } = policyData;

  const dateValidFrom = policyData.validFromDate;

  if (isMobileView) {
    return (
      <Stack className="w-full">
        <PolicyContacts
          {...{
            policyManager: {
              ...policyManager,
              image: policyManagerImage,
            },
            accountManager: {
              ...accountManager,
              image: accountManagerImage,
            },
            isMobileView,
          }}
        />

        <Stack
          direction="col"
          alignItems="center"
          className="bg-customColor_7 w-full px-5 mt-1 py-2 gap-4"
        >
          <Stack className="w-full">
            <p className={classnames(headerStyle)}>
              {t('policyDetails.insurer')}
            </p>

            <Stack className="w-full overflow-x-hidden">
              <p className={classnames(textStyle, 'w-full')}>
                {policyData.insuranceCompanyName}
              </p>
              <p className={textStyle}>{t('policyDetails.brokerServices')}</p>
              <p className={textStyle}>{localAddress}</p>
              <p className={textStyle}>
                {zipCode} {city}
              </p>
            </Stack>
          </Stack>

          <Stack direction="row" className="h-full w-full gap-4">
            <Stack className="w-1/2">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.branch')}
              </p>

              <Stack className="w-full">
                <p className={textStyle}>{policyData.branchName}</p>
              </Stack>
            </Stack>

            <Stack className="w-1/2 overflow-x-hidden">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.contractType')}
              </p>

              <Stack className="w-full">
                <p className={classnames(textStyle, 'w-full')}>
                  {policyData.contractTypeName}
                </p>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="row" className="h-full w-full gap-4">
            <Stack className="w-1/2">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.dateValidFrom')}
              </p>

              <Stack className="w-full">
                <p className={textStyle}>{dateFormat(dateValidFrom, true)}</p>
              </Stack>
            </Stack>

            <Stack className="w-1/2">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.expireDate')}
              </p>

              <Stack className="w-full">
                <p className={textStyle}>{dateFormat(expirationDate, true)}</p>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="row" className="h-full w-full gap-4">
            <Stack className="w-1/2">
              <p className={classnames(headerStyle)}>
                {t('policyDetails.brutto')}
              </p>
              <Stack className="w-full">
                <p className={textStyle}>{currencyFormat(bruttoPremium)}</p>
              </Stack>
            </Stack>
            {notes && (
              <Stack className="w-1/2 overflow-hidden">
                <p className={classnames(headerStyle)}>
                  {t('policyDetails.description')}
                </p>

                <Stack className="w-full max-h-11">
                  <p className={classnames(textStyle, 'w-full truncate h-6')}>
                    {notes}
                  </p>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      className="w-full h-[210px] gap-4"
    >
      <Stack
        direction="row"
        className="bg-customColor_7 w-full h-full mt-2 p-[30px]"
        justifyContent="between"
      >
        <Stack direction="col" className="h-full" justifyContent="between">
          <p className={classnames(headerStyle)}>
            {t('policyDetails.insurer')}
          </p>

          <Stack className="w-full">
            <p className={textStyle}>{policyData.insuranceCompanyName}</p>
            <p className={textStyle}>{t('policyDetails.brokerServices')}</p>
            <p className={textStyle}>{localAddress}</p>
            <p className={textStyle}>
              {zipCode} {city}
            </p>
          </Stack>
        </Stack>

        <Stack direction="col" className="h-full" justifyContent="between">
          <Stack>
            <p className={classnames(headerStyle)}>
              {t('policyDetails.branch')}
            </p>

            <Stack className="w-full">
              <p className={textStyle}>{policyData.branchName}</p>
            </Stack>
          </Stack>

          <Stack>
            <p className={classnames(headerStyle)}>
              {t('policyDetails.contractType')}
            </p>

            <Stack className="w-full">
              <p className={textStyle}>{policyData.contractTypeName}</p>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="col" className="h-full" justifyContent="between">
          <Stack>
            <p className={classnames(headerStyle)}>
              {t('policyDetails.dateValidFrom')}
            </p>

            <Stack className="w-full">
              <p className={textStyle}>{dateFormat(dateValidFrom, true)}</p>
            </Stack>
          </Stack>

          <Stack>
            <p className={classnames(headerStyle)}>
              {t('policyDetails.expireDate')}
            </p>

            <Stack className="w-full">
              <p className={textStyle}>{dateFormat(expirationDate, true)}</p>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction="col"
          className="h-full max-w-[240px] relative"
          justifyContent="between"
        >
          <Stack>
            <p className={classnames(headerStyle)}>
              {t('policyDetails.description')}
            </p>

            <Stack className="w-full max-h-11 overflow-hidden">
              <p
                className={classnames(
                  'text-sm font-normal line-clamp-2 leading-[21px]'
                )}
              >
                {notes}
              </p>
            </Stack>
          </Stack>

          <Stack>
            <p className={classnames(headerStyle)}>
              {t('policyDetails.brutto')}
            </p>
            <Stack className="w-full">
              <p className={textStyle}>{currencyFormat(bruttoPremium)}</p>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <PolicyContacts
        {...{
          policyManager: {
            ...policyManager,
            image: policyManagerImage,
          },
          accountManager: {
            ...accountManager,
            image: accountManagerImage,
          },
        }}
        onClick={(item) => {
          setBrokerData(item);
          setShowCreateConversation(true);
        }}
      />
    </Stack>
  );
};

export default PolicyData;
