import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { TopicType } from '@trueconnect/tp-components/src/providers/WorkManagement/typing';
import { Dispatch, SetStateAction, useEffect } from 'react';
import TopicsList from '@trueconnect/tp-components/src/pages/Topics/TopicsList';
import {
  SelectedTopicSidebarItem,
  useIsSelectedRange,
  useStateProvider,
} from '@trueconnect/tp-components';
import TopicsSidebar from '@trueconnect/tp-components/src/pages/Topics/TopicsSidebar';
import ConversationTopicsSidebarHeader from './ConversationTopicsSidebarHeader';
import { AllConversationsItem } from '@trueconnect/tp-components/src/pages/Topics';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

export const ConversationTopicsSidebar = ({
  queryTopics,
  setSearchParam,
  searchParam,
}: {
  queryTopics: UseInfiniteQueryResult<TopicType[], Error>;
  setSearchParam: Dispatch<SetStateAction<string>>;
  searchParam: string;
}) => {
  const { state, actions } = useStateProvider();
  const { showTopicsSidebar, selectedTopic } = state.workManagement;
  const { extendedSearchParams, conversationData } = state.conversation;
  const { selectedTopics } = extendedSearchParams;
  const { setSelectedTopic, setShowTopicsSidebar, setExtendedSearchParams } =
    actions;
  const isMobileView = useIsSelectedRange(['sm', 'md']);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedTopic.id && selectedTopics?.length == 1) {
      const id = selectedTopics[0];
      const item = (queryTopics?.data || [])
        .flat(1)
        .filter((item) => item?.id == id)?.[0];
      item && setSelectedTopic(item);
    }
    if (selectedTopics?.length && selectedTopics?.length >= 2) {
      setSelectedTopic({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopic.id, selectedTopics?.length]);

  if (searchParam == '' && !queryTopics.data?.flat(1).length) {
    return <></>;
  }

  return (
    <TopicsSidebar
      isFull={showTopicsSidebar}
      isMobileView={isMobileView}
      className={classnames('border-r', isMobileView && '!min-w-[45px]')}
    >
      <ConversationTopicsSidebarHeader
        {...{
          setSearchParam,
          isFull: showTopicsSidebar,
          setIsFull: setShowTopicsSidebar,
        }}
      />
      <AllConversationsItem {...{ isFull: showTopicsSidebar }} />
      {showTopicsSidebar && (
        <TopicsList
          {...{
            queryTopics,
            setSearchParam,
            isFull: showTopicsSidebar,
            secondaryText: 'creatorUserName',
            onClick: (item) => {
              if (item.id) {
                setExtendedSearchParams({
                  ...extendedSearchParams,
                  withoutTopic: undefined,
                });
                setSelectedTopic(item);
                if (item.id !== conversationData.topicId) {
                  navigate('/conversations');
                }
              }
            },
          }}
        />
      )}
      {!showTopicsSidebar && <SelectedTopicSidebarItem />}
    </TopicsSidebar>
  );
};

export default ConversationTopicsSidebar;
