import { IconButton } from './../../components';
import Stack from '../Stack';
import { InsurancesIcon, PhoneIcon } from './../../images';

export const CompanyInfo = () => {
  const name = 'True Partners AG';
  const address = 'Ziegelhüttenstrasse 12';
  const postIndex = 'CH-8307 Bisikon';
  const phone = '+41 (0)52 354 25 25';

  return (
    <Stack direction="col" className="w-full p-2 pb-10">
      <Stack direction="row">
        <IconButton hoverable={false} customSize="h-4 w-4" className="mr-2">
          <InsurancesIcon />
        </IconButton>
        <Stack direction="col">
          <p className="text-xs font-semibold">{name}</p>
          <p className="text-xs font-medium">{address}</p>
          <p className="text-xs font-medium">{postIndex}</p>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" className="pt-5">
        <IconButton hoverable={false} customSize="h-4 w-4" className="mr-2">
          <PhoneIcon />
        </IconButton>
        <Stack direction="col">
          <p className="text-xs font-semibold">{phone}</p>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CompanyInfo;
