import { getApiQueryMethods } from '@trueconnect/tp-components';
import defaultApiOptions from './defaultApiOptions';
import {
  ApiMutationFunctions,
  ApiQueryFunctions,
  CustomerAppLogic,
  CustomerAppLogicClient,
  queryPageMapping,
} from './CustomerAppLogic';

const customerApiMethods = getApiQueryMethods<
  CustomerAppLogicClient,
  ApiMutationFunctions,
  ApiQueryFunctions
>(CustomerAppLogic, defaultApiOptions, queryPageMapping);

export const useApiQuery = customerApiMethods.useApiQuery;
export const useApiMutation = customerApiMethods.useApiMutation;
export const useApiInfiniteQuery = customerApiMethods.useApiInfiniteQuery;
export const useApiInfiniteQueryWithPageToken =
  customerApiMethods.useApiInfiniteQueryWithPageToken;
export const useApiInvalidateQueries =
  customerApiMethods.useApiInvalidateQueries;
